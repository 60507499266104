import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'

/** Games */
import {Creators as gameActions} from '../../store/ducks/game'
import {Creators as authActions} from '../../store/ducks/auth'

import {Modal} from 'react-bootstrap'

import {NewPlatformModal} from '../../../_metronic/layout/components/header/NewPlatformModal'

const NewGame = () => {
  const intl = useIntl()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const game = useSelector((state: any) => state.game.current_game)
  const auth = useSelector((state: any) => state.auth)

  const [gameName, setGameName] = useState(game ? game.name : '')
  const [votingSystem, setVotingSystem] = useState(game ? game.voting_system_name : '')
  const [gameNameError, setGameNameError] = useState('')
  const [values, setValues] = useState<{[key: string]: any}>({
    fibonacci: [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, '?', '☕'],
    't-shirts': ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', '?', '☕'],
    'modified-fibonacci': [0, '½', 1, 2, 3, 5, 8, 13, 20, 40, 100, '?', '☕'],
    'power-2': [0, 1, 2, 4, 8, 16, 32, 64, '?', '☕'],
  })
  const [customValues, setCustomValues] = useState('')

  const [showPlatFormModal, setShowPlatformModal] = useState(true)

  /**OLD */
  const [selectView, setSelectView] = useState(false)

  useEffect(() => {
    dispatch(authActions.createAuthAnon())
  }, [])

  const cancelView = () => {
    if (selectView) setSelectView(false)
  }

  /** Functions */
  const createGame = () => {
    if (gameName.length < 1) {
      return setGameNameError('Field required')
    }

    const game = {
      name: gameName,
      voting_system:
        votingSystem === 'custom'
          ? customValues
              .split(',')
              .map((item) => item.trim())
              .join(',')
          : values[votingSystem].toString(),
      voting_system_name: votingSystem,
      user_id: auth.authenticated.uid,
      issue_votting_now: false,
    }
    dispatch(gameActions.clearBoard())

    dispatch(gameActions.createGame({...game}))
  }

  return (
    <div className='d-flex flex-column' onClick={() => cancelView()}>
      {/* <NewPlatformModal show={showPlatFormModal} handleClose={() => setShowPlatformModal(!showPlatFormModal)} /> */}
      <div className='text-center mb-13'>
        <h1 className='mb-3'>{intl.formatMessage({id: 'GAMES.CREATEGAME'})}</h1>
        <div className='text-muted fw-bold fs-5'>
          {intl.formatMessage({id: 'GAMES.CREATEGAME.DESCRIPTION'})}
        </div>
      </div>

      <div className='card container'>
        <div className='card-body'>
          <div>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='form-label required fs-5'>
                {intl.formatMessage({id: 'GAMES.GAMENAME'})}
              </span>
              <OverlayTrigger
                placement='right'
                delay={{show: 250, hide: 400}}
                overlay={(props) => (
                  <Tooltip id='button-tooltip' {...props}>
                    {intl.formatMessage({id: 'GAMES.GAMENAME.DESCRIPTION'})}
                  </Tooltip>
                )}
              >
                <span className='bg-transparent border-0'>
                  <i className='fas fa-exclamation-circle ms-2 fs-7'></i>
                </span>
              </OverlayTrigger>
            </label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='gamename'
              placeholder=''
              value={gameName}
              onChange={(e) => {
                setGameName(e.target.value)
                setGameNameError('')
              }}
            />
            {gameNameError && (
              <div className='fv-plugins-message-container'>
                <div data-field='gamename' data-validator='notEmpty' className='fv-help-block'>
                  {intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_FIELD'})}
                </div>
              </div>
            )}
          </div>

          <div className='fv-row'>
            {/* begin::Label */}
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4 mt-6'>
              <span className='form-label fs-5 required'>
                {intl.formatMessage({id: 'GAMES.VOTINGSYSTEM'})}
              </span>
            </label>
            {/* end::Label */}
            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='votingSystem'
                      value='fibonacci'
                      checked={votingSystem === 'fibonacci'}
                      onChange={(e) => {
                        setVotingSystem(e.target.value)
                      }}
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6 form-label'>Fibonacci</span>
                  <span className='fs-7 text-muted'>
                    0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ?, ☕{' '}
                  </span>
                </span>
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='votingSystem'
                      value='modified-fibonacci'
                      checked={votingSystem === 'modified-fibonacci'}
                      onChange={(e) => {
                        setVotingSystem(e.target.value)
                      }}
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6 form-label'>Modified Fibonacci</span>
                  <span className='fs-7 text-muted'>
                    0, ½, 1, 2, 3, 5, 8, 13, 20, 40, 100, ?, ☕
                  </span>
                </span>
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='votingSystem'
                      value='t-shirts'
                      checked={votingSystem === 't-shirts'}
                      onChange={(e) => {
                        setVotingSystem(e.target.value)
                      }}
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6 form-label'>T-shirts</span>
                  <span className='fs-7 text-muted'>xxs, xs, s, m, l, xl, xxl, ?, ☕</span>
                </span>
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='votingSystem'
                      value='power-2'
                      checked={votingSystem === 'power-2'}
                      onChange={(e) => {
                        setVotingSystem(e.target.value)
                      }}
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6 form-label'>Powers of 2</span>
                  <span className='fs-7 text-muted'>0, 1, 2, 4, 8, 16, 32, 64, ?, ☕</span>
                </span>
              </span>
            </label>
            {/*end::Option */}
            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='votingSystem'
                      value='custom'
                      checked={votingSystem === 'custom'}
                      onChange={(e) => {
                        setVotingSystem(e.target.value)
                      }}
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6 form-label'>
                    {intl.formatMessage({id: 'GAMES.CUSTOMDECK'})}
                  </span>
                  <span className='fs-7 text-muted'>
                    {intl.formatMessage({id: 'GAMES.CUSTOMDECK.DESCRIPTION'})}
                  </span>
                </span>
              </span>
            </label>
            {votingSystem === 'custom' && (
              <textarea
                className='form-control form-control-solid mb-8'
                rows={2}
                value={customValues}
                placeholder={intl.formatMessage({id: 'GAMES.CUSTOMDECK.PLACEHOLDER'})}
                onChange={(e) => setCustomValues(e.target.value.trim())}
              ></textarea>
            )}
            {votingSystem && (
              <div className='card-footer d-flex justify-content-between flex-wrap'>
                <div className='d-flex flex-column mb-3'>
                  <span>{intl.formatMessage({id: 'GAMES.CUSTOMDECK.PREVIEW'})}</span>
                  <div className='d-flex flex-row flex-wrap'>
                    {votingSystem === 'custom'
                      ? customValues.split(',').map((value: any) => (
                          <span
                            key={value}
                            className='badge badge-square badge-dark me-1 mt-2 mb-2'
                          >
                            {value}
                          </span>
                        ))
                      : values[votingSystem].map((value: any) => (
                          <span
                            key={value}
                            className='badge badge-square badge-dark me-1 mt-2 mb-2'
                          >
                            {value}
                          </span>
                        ))}
                  </div>
                </div>
                <div
                  onClick={() => createGame()}
                  className='btn btn-primary align-items-center justify-content-center d-flex'
                >
                  {intl.formatMessage({id: 'GAMES.START_NOW'})}
                </div>
              </div>
            )}

            {/*end::Option */}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
    </div>
  )
}

export {NewGame}
