import { combineReducers } from 'redux';

import game from './game';
import auth from './auth';
import sessions from './sessions'
const rootReducer = combineReducers({
  game,
  auth
});

export default rootReducer;
