import {all, call, takeLatest, put, select, fork, delay} from 'redux-saga/effects'
import {useNavigate} from 'react-router-dom'

import {Account, AppwriteException} from 'appwrite'

import {
  createGame as createGameApi,
  createPlayer as createPlayerApi,
  getGame as getGameApi,
  getPlayers as getPlayersApi,
  createIssues as createIssueApi
} from '../../services/games'

import {
createAccountAnon,
// updateUserInfo
} from '../../services/auth'



// import api from '../../services/apiCamara';
// import { api as apiSenado } from '../../screens/services/apiSenado';
// import { navigate } from "../../services/navigation";

import {Creators as GameActions, Types as GameTypes} from '../ducks/game'
import {Creators as AuthActions, Types as AuthTypes} from '../ducks/auth'

// import {Creators as SessionsActions, Types as SessionsTypes} from '../ducks/sessions'


// function* getDeputados() {
//   const { page, deputados } = yield select((state) => state.deputados);
//   try {
//     const response = yield call(
//       api.get,
//       `deputados?itens=100&pagina=${page}&ordenarPor=nome&idLegislatura=57`
//     );

//     if (response.data.dados.length === 0) {
//       yield put(
//         DeputadosActions.getDeputadosSuccess({
//           data: deputados,
//           page: null,
//         })
//       );
//       yield put(CounterActions.countAdd());
//     } else {
//       yield put(
//         DeputadosActions.getDeputadosSuccess({
//           data: [...deputados, ...response.data.dados],
//           page: page + 1,
//         })
//       );
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }



function* createGame(action) {
  try {
    const response = yield call(createGameApi, action)
    yield put(GameActions.createGameSuccess(response))

    window.history.pushState({}, '', `/game/${response}`)
    window.location.reload()

  } catch (error) {
    console.log(error)
  }
}

function* createPlayer(action) {
  try {
    const response = yield call(createPlayerApi, action)
    yield put(GameActions.createPlayerSuccess(response))

  } catch (error) {
    console.log(error)
  }
}

function* createIssue(action) {
  try {
    const response = yield call(createIssueApi, action)
    yield put(GameActions.createIssuesSuccess())

  } catch (error) {
    console.log(error)
  }
}

function* getGame(action) {
  try {

    const response = yield call(getGameApi, action)
    yield put(GameActions.getGameSuccess(response))
    yield call(createAuthAnon)

    // yield put(GameActions.createPlayerSuccess(response));
    //   const state = yield select((state) => state.game);

    // window.history.pushState({},'',`/game/${response?.$id}`)
    // window.location.reload();

    // yield put(DeputadoDetalhesActions.getDeputadoSuccess(response.data.dados));
    // yield put(CounterActions.countAdd());
  } catch (error) {
    console.log(error)
  }
}

// function* getGameSuccess(action) {
//   return console.log("game success realtime", action)
//   yield put(GameActions.getGameSuccess(action.payload))
// }

function* getPlayers(action) {
  try {
    const response = yield call(getPlayersApi, action)
      const {already_seated} = yield select((state) => state.game);

    console.log("response getPlayer", response)
    yield put(GameActions.getPlayersSuccess(response.documents))

    // yield put(GameActions.createPlayerSuccess(response));
    //   const state = yield select((state) => state.game);

    // window.history.pushState({},'',`/game/${response?.$id}`)
    // window.location.reload();

    // yield put(DeputadoDetalhesActions.getDeputadoSuccess(response.data.dados));
    // yield put(CounterActions.countAdd());
  } catch (error) {
    console.log(error)
  }
}

function* removeSingleSeat(action) {
  try {
    const response = yield call(createGameApi, action)
    console.log('Game criado', response)

    yield put(GameActions.createGameSuccess(response))

    window.history.pushState({}, '', `/game/${response?.$id}`)
    window.location.reload()

    // yield put(DeputadoDetalhesActions.getDeputadoSuccess(response.data.dados));
    // yield put(CounterActions.countAdd());
  } catch (error) {
    console.log(error)
  }
}

function* createAuthAnon() {
    const response = yield call(createAccountAnon)
    yield put(AuthActions.createAuthAnonSuccess(response))
}

// function* getDeputado(action) {
//   try {
//     const response = yield call(api.get, `deputados/${action.payload}`);
//     yield put(DeputadoDetalhesActions.getDeputadoSuccess([]));

//     yield put(DeputadoDetalhesActions.getDeputadoSuccess(response.data.dados));
//     yield put(CounterActions.countAdd());
//   } catch (error) {
//     console.log(error);
//   }
// }

// function* getSenadores() {
//   try {
//     const response = yield call(apiSenado.get, '/senador/lista/atual');

//     yield put(
//       SenadoresActions.getSenadoresSuccess(
//         response.data.ListaParlamentarEmExercicio.Parlamentares.Parlamentar
//       )
//     );
//     yield put(CounterActions.countAdd());
//   } catch (error) {
//     console.log(error);
//   }
// }

// function* getSenador(action) {
//   try {
//     const response = yield call(apiSenado.get, `senador/${action.payload}`);

//     yield put(
//       SenadorDetalhesActions.getSenadorSuccess(
//         response.data.DetalheParlamentar.Parlamentar
//       )
//     );
//     yield put(CounterActions.countAdd());
//   } catch (error) {
//     console.log(error);
//   }
// }

export default function* rootSaga() {
  return yield all([
    takeLatest(GameTypes.CREATE_GAME, createGame),
    takeLatest(GameTypes.CREATE_PLAYER, createPlayer),
    takeLatest(GameTypes.GET_GAME, getGame),
    takeLatest(GameTypes.GET_PLAYERS, getPlayers),
    takeLatest(AuthTypes.CREATE_AUTH_ANON, createAuthAnon),
    takeLatest(GameTypes.CREATE_GAME_ISSUES, createIssue),

  ])
}
