import React from 'react'
import {KTIcon} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'

type Props = {
  show: boolean
  handleSignUpShow: Dispatch<SetStateAction<boolean>>
  setShow: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
}

const LoginModal: React.FC<Props> = ({show, handleSignUpShow, setShow, handleClose}) => {
  const [usepassword, setUsePassword] = React.useState(false)
  const [forgot, setForgot] = React.useState(false)

  const handleUsePasswordshow = () => setUsePassword(true)
  const handleUsePasswordClose = () => setUsePassword(false)
  const handleForgotshow = () => setForgot(true)
  const handleForgotClose = () => setForgot(false)

  const onclick = () => {
    handleSignUpShow(true)
    handleClose()
  }
  const onUsePassword = () => {
    handleClose()
    handleUsePasswordshow()
  }
  const onForgot = () => {
    handleUsePasswordClose()
    handleForgotshow()
  }
  const onCreateAccount = () => {
    handleForgotClose()
    handleSignUpShow(true)
  }
  const onLogin = () => {
    handleForgotClose()
    setShow(true)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false}>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='text-center mb-13'>
            <h1 className='mb-3'>Login</h1>
          </div>

          <div className='btn btn-primary fw-bolder w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
            Login with Google
          </div>
          <div className='btn btn-success fw-bolder w-100 mb-8'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/github-icon.svg')}
              className='h-20px me-3'
            />
            Login with Github
          </div>

          <div className='separator d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>or</span>
          </div>
          <input className='form-control form-control-default mb-8' placeholder='Email' />

          <div className='btn btn-success w-100 mb-8 btn'>Login</div>
          <div className='d-flex space-between'>
            <div className='font-weight-bold btn' onClick={onclick}>
              Create account
            </div>
            <div className='font-weight-bold btn' onClick={onUsePassword}>
              Use password
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={usepassword} onHide={handleUsePasswordClose}>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={handleUsePasswordClose}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='text-center mb-13'>
            <h1 className='mb-3'>Login</h1>
          </div>

          <input className='form-control form-control-default mb-8' placeholder='Email' />
          <input
            className='form-control form-control-default mb-8'
            placeholder='Password'
            type='password'
          />
          <div className='btn btn-success w-100 mb-8 btn'>Login</div>
          <div className='d-flex space-between'>
            <div className='font-weight-bold btn' onClick={onclick}>
              Sign Up
            </div>
            <div className='font-weight-bold btn' onClick={onForgot}>
              Forgot?
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={forgot} onHide={handleForgotClose}>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleForgotClose}>
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='text-center mb-13'>
            <h1 className='mb-3'>Forgot Password</h1>
          </div>

          <input
            className='form-control form-control-default mb-8'
            placeholder='Email to send recovery link'
          />
          <div className='btn btn-success w-100 mb-8 btn'>Send recovery link</div>
          <div className='d-flex space-between'>
            <div className='font-weight-bold btn' onClick={onCreateAccount}>
              Create account
            </div>
            <div className='font-weight-bold btn' onClick={onLogin}>
              Login
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {LoginModal}
