import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'

/** Games */
import {Creators as gameActions} from '../../store/ducks/game'
import {useSelector, useDispatch} from 'react-redux'

import FileUploader from '../../../_metronic/layout/components/fileUploader/fileUploader'

import {updateGame, updateIssue} from '../../services/games'
import {useIntl} from 'react-intl'

import {getTeams} from '../../services/clickup'

import {read, utils, writeFile} from 'xlsx'

import Lottie from 'lottie-react'
import aiLoader from './ai-loader.json'

type Props = {
  show: boolean
  handleClose: () => void
  validContent: boolean
}

const OpenAiModal: React.FC<Props> = ({show, handleClose, validContent}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const auth = useSelector((state: any) => state.auth)
  const issues = useSelector((state: any) => state?.game?.issues || [])

  return (
    <>
      {validContent === true && (
        <Modal show={show} size='sm' className='' backdrop='static' centered>
          <div
            className='modal-header pb-0 border-0 justify-content-end'
            onClick={() => handleClose()}
          ></div>
          <div className='modal-body scroll-y mx-0 mx-xl-0 pt-0 pb-15'>
            <div className='mb-5 mt-5'>
              <h1 className='mb-3 text-center'>Generating...</h1>
            </div>
            <div className='mb-2 text-center'>
              <Lottie animationData={aiLoader} loop={true} />
              <p className='form-label mt-3 text center'>
                Based on your task name and your user story we'll provide some important questions
                for your team to discuss.
              </p>
            </div>
          </div>
        </Modal>
      )}
      {validContent === false && (
        <Modal show={show} size='sm' className='' backdrop='static' centered>
          <div
            className='modal-header pb-0 border-0 justify-content-end'
            onClick={() => handleClose()}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </div>
          <div className='modal-body scroll-y mx-0 mx-xl-0 pt-0 pb-15'>
            <div className='mb-5 mt-5'>
              <h1 className='mb-3 text-center'>Ops</h1>
            </div>
            <div className='mb-2 text-center'>
              <p className='form-label mt-3 text center'>
                We tried, but unfortunately we couldn't understand the relationship between your
                task title and your user story, we wrote an article on how you can use the <i className='fa-solid text-info fa-wand-sparkles'></i> A.I assistant in <a className='text-dark' target="_blank" href="https://planningpokerweb.com/virtual-assistant-for-product-teams/"><u>this link</u></a>.
              </p>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export {OpenAiModal}
