import React, {useState} from 'react'
import {KTIcon} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

import { deletePlayer } from '../../../../app/services/games'

type Props = {
  show: boolean
  handleClose: () => void,
  player: any
}

const UserActionsModal: React.FC<Props> = ({show, handleClose, player}) => {
  const intl = useIntl()

  function handleRemovePlayer(player_id: any) {
    deletePlayer(player_id)
    handleClose()
  }




  return (
    <Modal show={show} onHide={handleClose} size="sm" keyboard={false} centered>
      <div className='modal-header pb-0 border-0 justify-content-between'>
      <h3 className='mb-3'>{player?.name}</h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-5 pt-5 pb-15'>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center'>
            <div className='btn btn-light' onClick={() => handleRemovePlayer(player.player_id)}>
              <i className='fa fa-trash text-danger'></i> Remove Player
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {UserActionsModal}
