/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../helpers'

const InviteUsers: FC = () => {
  return (
    <div className='modal fade' id='kt_modal_invite_friends' aria-hidden='true'>
      <div className='modal-dialog modal-centered mw-650px mt-20'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='mb-13'>
              <h1 className='mb-3'>Invite players</h1>
            </div>

            <input
              className='form-control mb-8'
              placeholder="Game's url"
              value='https://planningpokeronline.com/eYE148bXETT4VeKMwN1V/'
            />

            <div className='d-flex flex-stack'>
              <div className='btn btn-success w-100 mb-8 font-size-15'>Copy invitation link</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {InviteUsers}
