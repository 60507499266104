import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'

/** Games */
import {Creators as gameActions} from '../../store/ducks/game'
import {useSelector, useDispatch} from 'react-redux'

import FileUploader from '../../../_metronic/layout/components/fileUploader/fileUploader'

import {updateGame, updateIssue} from '../../services/games'
import {useIntl} from 'react-intl'

import {getTeams} from '../../services/clickup'

import {OpenAiModal} from './OpenAiModal'

import {read, utils, writeFile} from 'xlsx'

import axios from 'axios'

const makeOpenAi = 'https://hook.us1.make.com/6a9qhfmw3a24bgvctba25469gsg4nh1a'

type Props = {
  show: boolean
  handleClose: () => void
  action: string
  issue: any
}

const IssueModal: React.FC<Props> = ({show, handleClose, action, issue}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [openAi, setOpenAi] = useState(false)
  const [loadingOpenAi, setLoadingOpenAi] = useState(false)
  const [showOpenAiModal, setShowOpenAiModal] = useState(false)
  const [aiContentValidation, setAiContentValidation] = useState(true)
  const [uri, setUri] = useState<any>(null)
  const [type, setType] = useState<any>(null)
  const [importFrom, setImportFrom] = useState<any>('')
  const [csvRows, setCsvRows] = useState<any>([])
  const [clickupPersonalToken, setClickupPersonalToken] = useState<any>('')
  const [clickupPersonalTokenStatus, setClickupPersonalTokenStatus] = useState<any>('')

  const auth = useSelector((state: any) => state.auth)
  const issues = useSelector((state: any) => state?.game?.issues || [])

  useEffect(() => {
    if (action === 'update') {
      findIssueById()
    } else {
      setTitle('')
      setDescription('')
      setType('')
      setUri('')
      setImportFrom(null)
      setCsvRows([])
    }
  }, [issue])

  function checkClickUpPersonalToken() {
    if (clickupPersonalToken.length === 44) {
      getTeams(clickupPersonalToken)
    } else {
      console.log(false)
    }
  }

  function checkContentForAi() {
    const topic = title
    const userStory = description

    const topicSize = topic.split(' ').length
    const userStorySize = userStory.split(' ').length

    if (topicSize < 3 || userStorySize < 11) {
      setAiContentValidation(false)
      return false
    } else {
      setAiContentValidation(true)
      return true
    }
  }

  async function openAiHelp() {
    const valid = checkContentForAi()
    setShowOpenAiModal(true)

    if (valid) {
      const locale = navigator.language
      try {
        const resp = await axios.post(makeOpenAi, {
          language: locale,
          topic: title,
          description: description,
        })

        const parseData = resp?.data
          .split('\n')
          .map((item: any) => {
            return item.concat(`\n ${locale === 'pt-BR' ? 'R: \n' : 'A: \n'}`)
          })
          .join('')

        setDescription(description.concat(`\n \n ------------ \n ${parseData}`))
        setOpenAi(true)
        setShowOpenAiModal(false)
      } catch (error) {
        setAiContentValidation(false)
      }
    }

    // console.log('navigator.language || navigator.userLanguage', navigator.language)
    // const response = await openai.chat.completions.create({
    //   model: 'gpt-3.5-turbo',
    //   messages: [
    //     {
    //       role: 'user',
    //       content: `Dado o tópico "${title}\n e a descrição "${description}" da tarefa, no contexto de desenvolvimento de software, quais são as questões críticas e relevantes que uma equipe de produtos e software deve responder para garantir uma implementação bem-sucedida e clara para esse cenário? Forneça o resultado apenas em forma de lista`,
    //     },
    //   ],
    //   temperature: 1,
    //   max_tokens: 683,
    //   top_p: 1,
    //   frequency_penalty: 0,
    //   presence_penalty: 0,
    // })

    // setDescription(description.concat(`\n \n #More info\n ${response.choices[0].message.content}`))
  }

  function findIssueById() {
    const result = issues.filter((_issue: any) => {
      return _issue.issue_id === issue
    })

    if (result.length > 0) {
      setTitle(result[0].title)
      setDescription(result[0].description)
      setType(result[0].type)
      setUri(result[0].uri)
      setOpenAi(result[0].ai)
    }
  }

  function createIssue() {
    const issue = {
      title: title,
      ai: openAi,
      type: type,
      uri: uri,
      description: description,
      game_id: window.location.pathname.split('game/')[1],
      user_id: auth?.authenticated?.uid,
      index: parseInt(issues.length),
    }
    dispatch(gameActions.createIssues(issue))
    handleClose()
    setTitle('')
    setDescription('')
    setUri('')
    setOpenAi(false)
    setAiContentValidation(false)
  }

  function createdCsvIssues() {
    csvRows.forEach((row: any) => {
      const issue = {
        title: row?.Title ? row?.Title : null,
        type: row?.Type ? row?.Type : null,
        uri: row?.Uri ? row?.Uri : null,
        description: row?.Description ? row?.Description : null,
        game_id: window.location.pathname.split('game/')[1],
        user_id: auth?.authenticated?.uid,
        imported_from: 'csv',
      }
      dispatch(gameActions.createIssues(issue))
    })
    setCsvRows([])
    setImportFrom(null)
    handleClose()
  }

  function _updateIssue() {
    const currentIssue = issues.filter((_issue: any) => {
      return _issue.issue_id === issue
    })[0]
    const _issue = {
      title: title,
      type: type,
      uri: uri,
      description: description,
      game_id: window.location.pathname.split('game/')[1],
      user_id: auth?.authenticated?.uid,
      issue_id: issue,
    }

    if (
      currentIssue.title === title &&
      currentIssue.type === type &&
      currentIssue.uri === uri &&
      currentIssue.description === description
    ) {
      handleClose()
    } else {
      updateIssue(_issue, {type: 'update'})
      handleClose()
    }
  }

  function handleType(value: string) {
    if (type === value) {
      return setType(null)
    } else {
      return setType(value)
    }
  }

  return (
    <>
      {showOpenAiModal ? (
        <OpenAiModal
          validContent={aiContentValidation}
          show={showOpenAiModal}
          handleClose={() => {
            setShowOpenAiModal(false)
            setAiContentValidation(false)
          }}
        />
      ) : (
        <Modal show={show} size='xl' className='' backdrop='static' centered>
          <div
            className='modal-header pb-0 border-0 justify-content-end'
            onClick={() => handleClose()}
          >
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          {!importFrom && (
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13 d-flex justify-content-between'>
                <h1 className='mb-3'>
                  {action === 'create'
                    ? intl.formatMessage({id: 'TASKS.CREATE_TASK'})
                    : intl.formatMessage({id: 'TASKS.UPDATE_TASK'})}
                </h1>
                {action !== 'update' && (
                  <div className='me-2'>
                    <Dropdown>
                      <Dropdown.Toggle id='dropdown-basic' className='btn btn-sm btn-secondary'>
                        <i className='fa fa-upload'></i> {intl.formatMessage({id: 'TASKS.IMPORT'})}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setImportFrom('CSV')}>
                          from CSV or XLS
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {}}>from Clickup (soon)</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
              <div className='mb-7'>
                <label className='form-label required'>
                  {intl.formatMessage({id: 'TASKS.TITLE'})}
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={title}
                  placeholder={intl.formatMessage({id: 'TASKS.TITLE.PLACEHOLDER'})}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                />
              </div>
              <div className='mb-7'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'TASKS.DESCRIPTION'})}
                </label>
                <textarea
                  rows={openAi === true ? 5 : 3}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                  className='form-control form-control-solid'
                  placeholder={intl.formatMessage({id: 'TASKS.DESCRIPTION.PLACEHOLDER'})}
                />
                {action === 'create' && (
                  <pre className='mt-3'>
                    Ensure you utilize{' '}
                    <a
                      href='https://planningpokerweb.com/scrum/what-is-user-stories-in-product-development-examples/'
                      target='_blank'
                      title='User Stories' rel="noreferrer"
                    >
                      <u>User Stories</u>
                    </a>{' '}
                    descriptions if you want to engage with the{' '}
                    <i className='fa-solid text-info fa-wand-sparkles'></i> A.I assistant, <a className='text-dark' href="https://planningpokerweb.com/virtual-assistant-for-product-teams/" target="_blank" rel="noreferrer"><u>learn more</u></a>.
                  </pre>
                )}
              </div>
              <div className='mb-7'>
                <label className='form-label'>Link</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='i.e: https://clickup.com/task/3922/3452/1103/4444'
                  onChange={(e) => {
                    setUri(e.target.value)
                  }}
                />
              </div>
              <div className='mb-5'>
                <span
                  className={`cursor-pointer badge badge-${
                    type === 'feature' ? 'primary' : 'light'
                  } me-3`}
                  onClick={() => handleType('feature')}
                >
                  Feature{' '}
                  {action === 'create' && (
                    <i
                      className={`fa-solid ms-1 ${
                        type === 'feature' ? 'text-white' : 'text-info'
                      } fa-wand-sparkles`}
                    ></i>
                  )}
                </span>
                <span
                  className={`cursor-pointer badge badge-${
                    type === 'bug' ? 'danger' : 'light'
                  } me-3`}
                  onClick={() => handleType('bug')}
                >
                  Bug
                </span>
                <span
                  className={`cursor-pointer badge badge-${
                    type === 'chore' ? 'dark' : 'light'
                  } me-3`}
                  onClick={() => handleType('chore')}
                >
                  Chore
                </span>
                <span
                  className={`cursor-pointer badge badge-${
                    type === 'datafix' ? 'warning' : 'light'
                  } me-3`}
                  onClick={() => handleType('datafix')}
                >
                  Datafix
                </span>
                <span
                  className={`cursor-pointer badge badge-${
                    type === 'other' ? 'info' : 'light'
                  } me-3`}
                  onClick={() => handleType('other')}
                >
                  Other
                </span>
              </div>
              {type === 'feature' &&
                description.length > 50 &&
                title.length > 14 &&
                action === 'create' && (
                  <div>
                    <p className='text-gray-800'>
                      You're embarking on a new feature, and with our A.I. assistant, we can guide
                      you in raising crucial questions for a successful implementation, ensuring the
                      team is aligned and confident in the development process.
                    </p>
                    <div onClick={() => openAiHelp()} className='btn btn-sm btn-light'>
                      <i className='fa-solid text-info fa-wand-sparkles'></i> Try
                    </div>
                  </div>
                )}

              {type === 'feature' &&
                (description.length <= 50 || title.length <= 14) &&
                action === 'create' && (
                  <div>
                    <p className='text-gray-800'>
                      <i className='fa-solid text-info fa-wand-sparkles'></i> We've developed an
                      advanced virtual assistant designed to provide your team with valuable
                      insights and foster meaningful discussions, ensuring the successful completion
                      of tasks. To make the most of this tool, please provide an accurate title and
                      description in the form of a user story, or you can just proceed adding this
                      task in the button below.
                    </p>
                  </div>
                )}

              {action === 'create' && (
                <div className='btn btn-primary w-100 mb-8 mt-6 btn' onClick={() => createIssue()}>
                  {intl.formatMessage({id: 'TASKS.ADD'})}
                </div>
              )}
              {action === 'update' && (
                <div
                  className='btn btn-primary w-100 mb-8 mt-6 btn'
                  onClick={() => {
                    _updateIssue()
                  }}
                >
                  {intl.formatMessage({id: 'TASKS.UPDATE'})}
                </div>
              )}
            </div>
          )}
          {importFrom === 'CSV' && (
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13 d-flex justify-content-between'>
                <div
                  className='btn btn-primary btn-sm btn btn-secondary'
                  onClick={() => {
                    setImportFrom(null)
                  }}
                >
                  <i className='fa fa-arrow-left'></i> Back
                </div>
              </div>
              <div className='mb-7'>
                <label className='form-label required'>Pick your file</label>

                <input
                  type='file'
                  className='form-control form-control-solid'
                  accept='.xlsx, .xls, .csv'
                  onChange={(e: any) => {
                    const files = e.target.files
                    if (files.length > 0) {
                      const file = files[0]
                      const reader = new FileReader()
                      reader.onload = (event: any) => {
                        const wb = read(event.target.result)
                        const sheets = wb.SheetNames

                        if (sheets.length) {
                          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                          setCsvRows(rows)
                        }
                      }
                      reader.readAsArrayBuffer(file)
                    }
                  }}
                />
              </div>
              <label className='form-label required'>
                Make sure your files headers are exactly as below.
              </label>

              <table className='table table-rounded table-striped gy-7 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th style={{width: '25%'}}>Title</th>
                    <th style={{width: '35%'}}>Description</th>
                    <th style={{width: '15%'}}>Type</th>
                    <th style={{width: '35%'}}>Uri</th>
                  </tr>
                </thead>
              </table>
              {csvRows && (
                <table className='table table-rounded table-striped gy-7 gs-7'>
                  <tbody>
                    {csvRows.map((item: any) => {
                      return (
                        <tr>
                          <td style={{width: '25%'}}>{item.Title}</td>
                          <td style={{width: '35%'}} className='custom-pre-line'>
                            {item.Description}
                          </td>
                          <td style={{width: '15%'}}>{item.Type}</td>
                          <td style={{width: '35%'}}>{item.Uri}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}

              <button
                className='btn btn-primary w-100 mb-8 mt-6 btn'
                disabled={csvRows?.length === 0}
                onClick={() => (csvRows.length > 0 ? createdCsvIssues() : {})}
              >
                Import tasks
              </button>
            </div>
          )}

          {importFrom === 'Clickup' && (
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13 d-flex justify-content-between'>
                <div
                  className='btn btn-primary btn-sm btn btn-secondary'
                  onClick={() => {
                    setImportFrom(null)
                  }}
                >
                  <i className='fa fa-arrow-left'></i> Back
                </div>
                {clickupPersonalToken}
              </div>
              <div className='mb-7'>
                <label className='form-label required'>Add your Clickup personal token</label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    accept='.xlsx, .xls, .csv'
                    onChange={(e: any) => {
                      setClickupPersonalToken(e.target.value)
                    }}
                  />
                  <div className='input-group-text p-2' id='basic-addon2'>
                    <div
                      className='btn btn-sm btn-light'
                      onClick={() => {
                        checkClickUpPersonalToken()
                      }}
                    >
                      Check
                    </div>
                  </div>
                </div>
              </div>
              <label className='form-label required'>
                Make sure your files headers are exactly as below.
              </label>

              <table className='table table-rounded table-striped gy-7 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th style={{width: '25%'}}>Title</th>
                    <th style={{width: '35%'}}>Description</th>
                    <th style={{width: '15%'}}>Type</th>
                    <th style={{width: '35%'}}>Uri</th>
                  </tr>
                </thead>
              </table>
              {csvRows && (
                <table className='table table-rounded table-striped gy-7 gs-7'>
                  <tbody>
                    {csvRows.map((item: any) => {
                      return (
                        <tr>
                          <td style={{width: '25%'}}>{item.Title}</td>
                          <td style={{width: '35%'}} className='custom-pre-line'>
                            {item.Description}
                          </td>
                          <td style={{width: '15%'}}>{item.Type}</td>
                          <td style={{width: '35%'}}>{item.Uri}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}

              <button
                className='btn btn-primary w-100 mb-8 mt-6 btn'
                disabled={csvRows?.length === 0}
                onClick={() => (csvRows.length > 0 ? createdCsvIssues() : {})}
              >
                Import tasks from ClickUp
              </button>

              {/* <div className='d-flex space-between'>
          <div className='font-weight-bold btn' onClick={onCreateAccount}>
            Sign Up
          </div>
          <div className='font-weight-bold btn' onClick={onLogin}>
            Login
          </div>
        </div> */}
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export {IssueModal}
