import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from './ducks'
import sagas from './sagas'

const persistConfig = {
  key:'@planningpokerweb',
  storage,
  whitelist: ['game', 'auth']
}

const persistedReducer = persistReducer(persistConfig, reducers)

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
// const store = configureStore({
//   reducer: reducers, 
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware(persistedReducer).concat(sagaMiddleware),
// })

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [sagaMiddleware]
})


// then run the saga
sagaMiddleware.run(sagas)

export const persistor = persistStore(store)
