import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {Dispatch, SetStateAction, useState, useRef} from 'react'
import { differenceInHours } from 'date-fns'


/** Games */
import {Creators as gameActions} from '../../store/ducks/game'
import {useSelector, useDispatch} from 'react-redux'

type Props = {
  show: boolean
  handleClose: () => void
  handleLoginShow: Dispatch<SetStateAction<boolean>>
  handleSignUpShow: Dispatch<SetStateAction<boolean>>
}

const DisplayNameModal: React.FC<Props> = ({
  show,
  handleClose,
  handleLoginShow,
  handleSignUpShow,
}) => {
  const dispatch = useDispatch()

  const createBtnRef = useRef<HTMLButtonElement | null>(null)

  const [name, setName] = useState('')
  const [observer, setObserver] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const auth = useSelector((state: any) => state.auth)
  const game = useSelector((state: any) => state.game)

  const onCreateAccount = () => {
    handleClose()
    handleSignUpShow(true)
  }
  const onLogin = () => {
    handleClose()
    handleLoginShow(true)
  }

  function fomartName(name:any) {
    if (!name) return '';

    const nameParts = name.trim().split(' ');
  
    if (nameParts.length === 1) {
      return nameParts[0];
    }
  
    const lastNameInitial = nameParts[nameParts.length - 1][0];
    return `${nameParts[0]} ${lastNameInitial}.`;

  }

  function createSeat() {
    const isOwner = auth?.authenticated?.uid === game?.data?.user_id
    if (name.length < 2) {
      return setError(true)
    }

    const formatedName = fomartName(name)

    const seat = {
      name: formatedName,
      observer: observer,
      game_id: window.location.pathname.split('game/')[1],
      user_id: auth?.authenticated?.uid,
      owner: isOwner,
    }
    handleClose()
    dispatch(gameActions.createPlayer(seat))
    setLoading(false)
  }
  return (
    <>
      <Modal show={show} className='' backdrop='static' centered>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary'></div>
        </div>
        {/* <p>Game criado: {JSON.stringify(game?.data?.created_at.toDate())}</p>
        <p>Data atual: {JSON.stringify(new Date())}</p>
        <p>Diference in Hours: {differenceInHours(new Date(), game?.data?.created_at.toDate())}</p> */}


        {game?.data?.created_at ? (
          <div className='modal-body  scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3 text-gray-700'>Let's begin, take your seat</h1>
            </div>
            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Type your name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {error && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    This field is required
                  </div>
                </div>
              )}
            </div>

            <div className='justify-content-center form-check form-check-custom form-check-solid form-check-primary form-switch mb-8 mt-8'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_builder_sidebar_minimize_desktop_hoverable'
                name='model.app.sidebar.default.minimize.desktop.hoverable'
                defaultChecked={observer}
                onChange={() => setObserver(!observer)}
              />

              <label
                className='form-check-label text-gray-800 fw-bold'
                htmlFor='kt_builder_sidebar_minimize_desktop_hoverable'
                data-bs-toggle='tooltip'
                data-kt-initialized='0'
              >
                Join as spectator
              </label>
            </div>

            {name.length > 2 && (
              <button
                className='btn btn-primary w-100 mb-8 btn'
                ref={createBtnRef}
                onClick={() => {
                  createBtnRef.current?.setAttribute('disabled', 'true')
                  setLoading(true)
                  createSeat()
                }}
                data-kt-indicator={loading === true ? 'on' : 'off'}
              >
                <span className='indicator-label'>You're rock, let's go!</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            )}

            {/* <div className='d-flex space-between'>
            <div className='font-weight-bold btn' onClick={onCreateAccount}>
              Sign Up
            </div>
            <div className='font-weight-bold btn' onClick={onLogin}>
              Login
            </div>
          </div> */}
          </div>
        ) : (
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Ops, this game not exists</h1>
              <p className='fs-5'>
                Apparently this game has already ended or does not exist, ask the session moderator
                for the correct link.
              </p>
            </div>

            <p className='text-center'>or you can</p>
            <a href='/create-game' className='btn btn-primary w-100 mb-8 btn'>
              Create a new game
            </a>

            {/* <div className='d-flex space-between'>
            <div className='font-weight-bold btn' onClick={onCreateAccount}>
              Sign Up
            </div>
            <div className='font-weight-bold btn' onClick={onLogin}>
              Login
            </div>
          </div> */}
          </div>
        )}
      </Modal>
    </>
  )
}

export {DisplayNameModal}
