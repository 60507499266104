import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {Footer} from '../../_metronic/layout/components/Footer'
import {HeaderWrapper} from './HeaderWrapper'
import {ScrollTop} from '../../_metronic/layout/components/ScrollTop'
import {Content} from '../../_metronic/layout/components/Content'
import {PageDataProvider} from '../../_metronic/layout/core'
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  ThemeModeProvider,
  UpgradePlan,
} from '../../_metronic/partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'

const CustomLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  // let issue = localStorage.getItem('issue')

  useEffect(() => {
  }, [location.pathname])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <div className='d-flex flex-column flex-row-fluid mywrapper' id='kt-wrapper'>
              <HeaderWrapper title={location.pathname === '/create-game' ? 'Create game' : 'Hi'} />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* {location.pathname !== '/create-game' && <Footer />} */}
            </div>
          </div>
        </div>
        <DrawerMessenger />

        <ActivityDrawer />

        <InviteUsers />
        <UpgradePlan />
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {CustomLayout}
