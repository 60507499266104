import React, {Dispatch, SetStateAction} from 'react'
import {KTIcon} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import {Modal} from 'react-bootstrap'

import { googleSignUp } from '../../../../app/services/auth'



type Props = {
  show: boolean
  setLoginshow: Dispatch<SetStateAction<boolean>>
  setShow: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
}

const Signupmodal: React.FC<Props> = ({show, setShow, setLoginshow, handleClose}) => {
  const onLogin = () => {
    setLoginshow(true)
    handleClose()
  }

  function _googleSignUp() {
    googleSignUp()
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className='' backdrop='static' centered>
        <div className='modal-header pb-0 border-0 justify-content-end '>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-5 pt-0 pb-15'>
          <div className='text-center mb-13'>
            <h1 className='mb-3'>Sign Up 2</h1>
          </div>

          <div className='btn btn-light fw-bolder w-100 mb-5' onClick={()=> {_googleSignUp()}}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
            with Google
          </div>
          <div className='btn btn-light fw-bolder w-100 mb-8'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/github-icon.svg')}
              className='h-20px me-3'
            />
            with Github
          </div>

          <div className='separator d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>or</span>
          </div>
          <input type="text" className='form-control form-control-solid mb-8' placeholder='Email' />
          <input type="password" className='form-control form-control-solid mb-8' placeholder='Password' />

          <div className='mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' value='' id='flexCheckDefault' />
              <label className='form-check-label' htmlFor='flexCheckDefault'>
                I agree to the <a href='/'>Legal notice</a>
              </label>
            </div>
          </div>

          <div className='btn btn-primary w-100 mb-8' onClick={handleClose}>
            Sign up
          </div>
          <div className='text-align-center'>
            Already have an account?{'  '}
            <span className='font-weight-bold btn' onClick={onLogin}>
              Login
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Signupmodal
