/** FIRESTORE **/
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getDatabase } from "firebase/database";

/** REAL TIME */


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsLVp5t0RbpaujdW7Q8MHOv4g_ozLfcvY",
  authDomain: "planning-poker-web-67aa6.firebaseapp.com",
  projectId: "planning-poker-web-67aa6",
  storageBucket: "planning-poker-web-67aa6.appspot.com",
  messagingSenderId: "740389717766",
  appId: "1:740389717766:web:1ae0f60c710197875c8087",
  measurementId: "G-9X0ZWBQQ3L",
  databaseURL: "https://planning-poker-web-67aa6-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app)
const dbrealtime = getDatabase(app)
export {db, app, dbrealtime}
