import {Account, AppwriteException, Databases, ID, Query} from 'appwrite'
import {
  collection,
  addDoc,
  Timestamp,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'

import LogRocket from 'logrocket'
import posthog from 'posthog-js'

import {getAuth, updateProfile} from 'firebase/auth'
import {User} from '@firebase/auth-types'

import {ref, set} from 'firebase/database'

import client from './api'

import {db, dbrealtime} from './firebase'

import {updateUserInfo} from './auth'

const account = new Account(client)
const databases = new Databases(client)

/** Auth */

// export const checkSession = async () => {
//   try {
//     const session = await account.get()
//     return session
//   } catch (error) {
//     const appwriteError = error as AppwriteException
//     throw new Error(appwriteError.message)
//   }
// }

/** Game */
export const createGame = async ({payload}: any) => {
  try {
    const data = await addDoc(collection(db, 'games'), {
      ...payload,
      created_at: Timestamp.now(),
    })

    return data.id
  } catch (err) {
    alert(err)
  }

  // try {
  //   const game = await databases.createDocument('planningpokerweb', 'games', ID.unique(), payload)
  //   return game
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const createIssues = async ({payload}: any) => {
  // return console.log("trying import issue", payload)
  try {
    const issue = await addDoc(collection(db, 'issues'), {
      ...payload,
      average: 0,
      created_at: Timestamp.now(),
    })

    return true
  } catch (err) {
    console.log(err)
  }

  // try {
  //   const player = await databases.createDocument('planningpokerweb', 'players', ID.unique(), payload)

  //   return player
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const createVote = async (payload: any) => {
  try {
    const vote = await addDoc(collection(db, 'issues_votes'), {
      ...payload,
      created_at: Timestamp.now(),
    })

    return true
  } catch (err) {
    console.log(err)
  }

  // try {
  //   const player = await databases.createDocument('planningpokerweb', 'players', ID.unique(), payload)

  //   return player
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const updateIssue = async (payload: any, action: any) => {
  if (action.type === 'reorder') {
    try {
      const issueRef = doc(db, 'issues', payload.issue_id)
      await updateDoc(issueRef, {
        index: payload.index,
      })
    } catch (err) {
      console.log(err)
    }
  }

  if (action.type === 'reveal') {
    try {
      const issueRef = doc(db, 'issues', payload.issue_id)
      await updateDoc(issueRef, {
        reveal: payload.reveal,
        average: payload.average,
      })
    } catch (err) {
      console.log(err)
    }
  }

  if (action.type === 'vote-again') {
    try {
      const issueRef = doc(db, 'issues', payload.issue_id)
      const issueSnap = await getDoc(issueRef)

      let payload_internal = null

      if (issueSnap.exists()) {
        payload_internal = {
          game_id: issueSnap.data()?.game_id,
          issue_id: payload.issue_id,
        }
      } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!')
      }

      //remove players-votes

      const votesRef = collection(db, 'issues_votes')
      const queryData = query(votesRef, where('issue_id', '==', payload.issue_id))
      const querySnapshot = await getDocs(queryData)
      querySnapshot.forEach((doc: any) => {
        // doc.data() is never undefined for query doc snapshots
        deleteDoc(doc.ref)
      })

      //atualiza game
      await updateGame(payload_internal)

      //atualiza issue
      await updateDoc(issueRef, {
        reveal: payload.reveal,
      })
    } catch (err) {
      console.log(err)
    }
  }

  if (action.type === 'update') {
    try {
      const issueRef = doc(db, 'issues', payload.issue_id)
      //atualiza issue
      const {issue_id, ...dataRest} = payload
      await updateDoc(issueRef, {
        ...dataRest,
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const updateVote = async (payload: any) => {
  try {
    const voteRef = doc(db, 'issues_votes', payload.vote_id)
    await updateDoc(voteRef, {
      vote_value: payload.value,
    })
  } catch (err) {
    console.log(err)
  }
}

export const deleteIssue = async (payload: any) => {
  try {
    await deleteDoc(doc(db, 'issues', payload.issue_id))

    return true
  } catch (err) {
    console.log(err)
  }

  // try {
  //   const player = await databases.createDocument('planningpokerweb', 'players', ID.unique(), payload)

  //   return player
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const deletePlayer = async (player_id: any) => {
  try {
    const playeRef = doc(db, 'players', player_id)
    const gameUpdated = updateDoc(playeRef, {
      connected: false,
    })

    return true
  } catch (err) {
    console.log(err)
  }
}

export const deleteVote = async (payload: any) => {
  try {
    await deleteDoc(doc(db, 'issues_votes', payload.vote_id))

    return true
  } catch (err) {
    console.log(err)
  }

  // try {
  //   const player = await databases.createDocument('planningpokerweb', 'players', ID.unique(), payload)

  //   return player
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const createPlayer = async ({payload}: any) => {
  try {
    const player = await addDoc(collection(db, 'players'), {
      ...payload,
      connected: true,
      created: Timestamp.now(),
    })

    updateUserInfo(payload?.name)

    const playerData = await getDoc(doc(db, 'players', player.id))

    LogRocket.identify(playerData?.data()?.user_id, {
      name: payload?.name,
    })

    posthog?.identify(playerData?.data()?.user_id, {
      name: payload?.name,
    })

    return {...playerData.data(), player_id: player.id}
  } catch (err) {
    console.log(err)
  }

  // try {
  //   const player = await databases.createDocument('planningpokerweb', 'players', ID.unique(), payload)

  //   return player
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const getGame = async ({payload}: any) => {
  const gameRef = doc(db, 'games', payload)
  const getGame = await getDoc(gameRef)
  const gameData = getGame.data()
  return {...gameData, game_id: gameRef.id}
}

export const updateGame = async (payload: any) => {
  // return console.log("atualizou a issue voting", payload)
  const gameRef = doc(db, 'games', payload.game_id)
  const gameUpdated = updateDoc(gameRef, {
    issue_votting_now: payload.issue_id,
  })

  return gameUpdated
}

export const updatePlayer = async (payload: any, type: any) => {

  //  console.log("Update Player ->", payload)
  // return console.log("Update Player Type ->", typeof(type))

  // return console.log("atualizou a issue voting", payload)
  const playeRef = doc(db, 'players', payload.player_id)
  if (type === 'observer') {
    const playerObserverUpdated = updateDoc(playeRef, {
      observer: payload.observer,
    })
    return playerObserverUpdated
  }
  if(type === 'name') {
    const playerNameUpdated = updateDoc(playeRef, {
      name: payload.name,
    })
    return playerNameUpdated
  }
}

export const getPlayers = async ({payload}: any) => {
  const players = query(collection(db, 'players'), where('game_id', '==', payload))
  // players.data()
  // try {
  //   const players = await databases.listDocuments('planningpokerweb', 'players', [
  //     Query.equal('game_id', payload),
  //   ])
  //   return players
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const getUserData = async () => {
  try {
    const account = new Account(client)
    return account.get()
  } catch (error) {
    const appwriteError = error as AppwriteException
    throw new Error(appwriteError.message)
  }
}

export const login = async (email: string, password: string) => {
  try {
    const account = new Account(client)
    return account.createEmailSession(email, password)
  } catch (error) {
    const appwriteError = error as AppwriteException
    throw new Error(appwriteError.message)
  }
}

export const logout = async () => {
  try {
    const account = new Account(client)
    return account.deleteSession('current')
  } catch (error: unknown) {
    const appwriteError = error as AppwriteException
    throw new Error(appwriteError.message)
  }
}

export const register = async (email: string, password: string) => {
  try {
    const account = new Account(client)
    return account.create('unique()', email, password)
  } catch (error) {
    const appwriteError = error as AppwriteException
    throw new Error(appwriteError.message)
  }
}
