/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon} from '../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../_metronic/helpers'

import {HeaderUserMenu, ThemeModeSwitcher} from '../../_metronic/partials'
import {Dropdown1} from './Dropdown1'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import Signupmodal from '../../_metronic/layout/components/header/SignUpmodal'
import {useEffect, useState} from 'react'
import {LoginModal} from '../../_metronic/layout/components/header/LoginModal'
import {InviteModal} from '../../_metronic/layout/components/header/InviteModal'
import {IssueBar} from './IssueBar'
import {DisplayNameModal} from './modals/DisplayNameModal'
import {useSelector, useDispatch} from 'react-redux'
import {Languages} from '../../_metronic/partials/layout/header-menus/Languages'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  title: string
}

const Topbar: React.FC<Props> = ({title}) => {
  const intl = useIntl()

  const [signUpshow, setSignUpshow] = useState(false)
  const [loginshow, setLoginshow] = useState(false)
  const [inviteshow, setInviteshow] = useState(false)
  const [issueshow, setIssueshow] = useState(false)
  const [dispNameshow, setDispNameshow] = useState(false)
  const [showTaskGuide, setShowTaskGuide] = useState(true)

  const handleSignUpClose = () => setSignUpshow(false)
  const handleSignUpShow = () => setSignUpshow(true)
  const handleLoginClose = () => setLoginshow(false)
  const handleLoginShow = () => setLoginshow(true)
  const handleInviteClose = () => setInviteshow(false)
  const handleInviteShow = () => setInviteshow(true)
  const handleIssueClose = () => setIssueshow(false)
  const handleIssueToggle = () => setIssueshow(!issueshow)
  // const handleDispNameShow = () => setDispNameshow(true)
  const handleDispNameClose = () => setDispNameshow(false)

  /** data */
  const game = useSelector((state: any) => state.game)
  const auth = useSelector((state: any) => state.auth)

  const isOwner = game?.data?.user_id === auth?.authenticated?.uid

  function checkLocalStorage() {
    let guideShow = localStorage.getItem('@planningpokerweb')
    

    if (guideShow) {
      setShowTaskGuide(false)
    }
  }

  //add to a specific context later

  function displayName() {
    const authDisplayName = auth?.authenticated?.displayName 
    const alreadySeatDisplayName = game?.already_seated?.name;

    if(auth.displayName) {
      return authDisplayName
    } else if(alreadySeatDisplayName) {
      return alreadySeatDisplayName
    } else {
      return "Join"
    }
  }

  function handleTaskGuide() {
    localStorage.setItem(
      '@planningpokerweb',
      JSON.stringify({guideShow: true, created_at: new Date()})
    )
    setShowTaskGuide(false)
  }

  useEffect(() => {
    checkLocalStorage()
    displayName()
    if (
      window.location.pathname.split('/')[1] === 'game' &&
      game?.already_seated?.game_id !== game?.data?.game_id
    ) {
      return setDispNameshow(true)
    } else {
      return setDispNameshow(false)
    }
  }, [game])

  return (
    <>
      <div className='d-flex flex-shrink-0 align-center'>
        <DisplayNameModal
          show={dispNameshow}
          handleClose={handleDispNameClose}
          handleLoginShow={handleLoginShow}
          handleSignUpShow={handleSignUpShow}
        />

        {/* begin::Invite user */}
        <div className='d-flex ms-3 pull-left accordion' id='kt_accordion_1'>
          <div className='d-flex flex-align-center flex-justify-center'>
            <a href={window.location.pathname.split('/')[1] === 'game' ? `https://planningpokerweb.com/?refid=${window.location.pathname.split("game/")[1]}` : 'https://planningpokerweb.com'}>
            <img
              src={toAbsoluteUrl('/media/logo-planning-poker-web.png')}
              alt='Planning Poker Web'
              className='h-35px'
            />
            </a>

            {game?.data?.name && window.location.pathname.split('/')[1] === 'game' ? (
              <div className='d-flex align-items-center'>
                <h3 className='mt-2 ms-4 text-gray-800'>{game?.data?.name}</h3>
                {/* <i className={`fas fa-gear fs-2 ms-2`}></i> */}
              </div>
            ) : (
              <h1 className='mt-2 ms-4 text-gray-800'>
                {intl.formatMessage({id: 'GAMES.CREATEGAME'})}
              </h1>
            )}
            {/* {title === 'Hi' ? (
          <div> */}
            {/* <button
              className='accordion-button fs-4 fw-bold collapsed btn btn-flex flex-center h-40px px-2  px-md-6 custom-logo-button'
              type='button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <span className='d-md-inline'>Bryan</span>
            </button>
            <Dropdown1 /> */}
            {/* 
            <h3 className='mt-2 ms-4'>Team 5 - Sprint 125</h3>

            
          </div>
        ) : (
          <h1 className='mt-2 ms-4'>Create game</h1>
        )} */}
          </div>
        </div>

        <div className='d-flex'>
          <div className='d-flex '>
            <div
              className=''
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <div className='btn btn-flex flex-between bg-body btn-color-gray-700 btn-active-color-primary h-40px px-3 px-md-6'>
                {/* <img
                  src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
                  alt='T'
                  className='user-avatar'
                /> */}
                <KTIcon iconName='user' className='fs-2 text-primary me-0 me-md-2' />

                <span className='d-none d-md-inline'>
                  {displayName()}
                </span>
                <KTIcon iconName='down' className='fs-2 text-primary user-avatar-icon' />
              </div>
            </div>
            <HeaderUserMenu />
          </div>
          {/* 
        <div className='d-flex ms-3'>
          <div
            className='md-visible btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
            onClick={handleSignUpShow}
          >
            <span className='d-none d-md-inline'>Sign Up</span>
          </div>
        </div> */}

          <Signupmodal
            handleClose={handleSignUpClose}
            setLoginshow={setLoginshow}
            show={signUpshow}
            setShow={handleSignUpShow}
          />

          {/* <div className='d-flex ms-3'>
          <div
            className='md-visible btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
            onClick={handleLoginShow}
          >
            <span className='d-none d-md-inline'>Login</span>
          </div>
        </div> */}

          <LoginModal
            handleClose={handleLoginClose}
            handleSignUpShow={handleSignUpShow}
            show={loginshow}
            setShow={handleLoginShow}
          />

          {game?.data?.name && window.location.pathname.split('/')[1] === 'game' && (
            <div className='d-flex ms-3 ms-3-custom'>
              <div
                className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
                onClick={handleInviteShow}
              >
                <KTIcon iconName='share' className='fs-2 text-primary me-0 me-md-2' />
                <span className='d-none d-md-inline'>
                  {intl.formatMessage({id: 'INVITEYOURTEAM'})}
                </span>
              </div>
            </div>
          )}

          <InviteModal show={inviteshow} handleClose={handleInviteClose} />
          <IssueBar show={issueshow} handleClose={handleIssueClose} />

          <div className='d-flex align-items-center  ms-3'>
            <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
          </div>

          {/* CHAT */}
          {game?.data?.name && window.location.pathname.split('/')[1] === 'game' && (
            <>
              <OverlayTrigger
                show={showTaskGuide}
                placement='auto-end'
                delay={{show: 250, hide: 400}}
                overlay={(props) => (
                  <Tooltip id='button-tooltip' {...props}>
                    {isOwner && (
                      <>
                        <p>Create your tasks here then your team will be able to vote</p>
                        <div className='btn btn-sm btn-light' onClick={() => handleTaskGuide()}>
                          I got it!
                        </div>
                      </>
                    )}
                    {!isOwner && (
                      <>
                        <p>Here you can follow the tasks</p>
                        <div className='btn btn-sm btn-light' onClick={() => handleTaskGuide()}>
                          I got it!
                        </div>
                      </>
                    )}
                  </Tooltip>
                )}
              >
                <div className='d-flex ms-3'>
                  <div
                    className='pulse btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 ps-md-6'
                    onClick={handleIssueToggle}
                  >
                    <span className='d-none d-md-inline pe-3'>
                      {intl.formatMessage({id: 'GAMES.TASKS'})}
                    </span>
                    <span className='pulse-ring' />
                    <KTIcon iconName='burger-menu-6' className='fs-2 text-primary me-0 pe-md-3' />
                  </div>
                </div>
              </OverlayTrigger>
              {/* <div className='d-flex align-items-center ms-3  ms-3-custom'>
              <div
                className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
                onClick={handleIssueToggle}
              >
                <KTIcon iconName='message-text-2' className='fs-2' />
                <span className='pulse-ring' />
              </div>
            </div> */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {Topbar}
