export const Types = {
  CREATE_SESSION: 'CREATE_SESSION',
  CREATE_SESSION_SUCCESS: 'CREATE_SESSION/SUCCESS',
  CREATE_SESSION_FAIL: 'CREATE_SESSION/FAIL',
  LIST_SESSION: 'LIST_SESSIONS',
  DELETE_SESSION: 'CREATE_PLAYER',
}

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
}

export default function sessions(state = INITIAL_STATE, action) {
  const {CREATE_SESSION, CREATE_SESSION_SUCCESS, CREATE_SESSION_FAIL, LIST_SESSION, DELETE_SESSION} =
    Types
  switch (action.type) {
    case CREATE_SESSION:
      return {...state, loading: true}
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }
    case CREATE_SESSION_FAIL:
      return {...state, loading: false, error: true}
    case LIST_SESSION:
      return {...state, data: action.payload, loading: false, error: false}
    case DELETE_SESSION:
      return {...state, loading: false, error: true, data: null}
    default:
      return state
  }
}

export const Creators = {
  createSession: () => ({
    type: Types.CREATE_SESSION,
  }),
  createSessionSuccess: (payload) => ({
    type: Types.CREATE_SESSION_SUCCESS,
    payload,
  }),
  createSessionFail: () => ({
    type: Types.CREATE_SESSION_FAIL,
  }),
  listSession: (payload) => ({
    type: Types.LIST_SESSION,
    payload,
  }),
  deleteSession: () => ({
    type: Types.DELETE_SESSION,
  }),
}
