export const Types = {
  CREATE_GAME: 'CREATE_GAME',
  CREATE_GAME_SUCCESS: 'CREATE_GAME/SUCCESS',
  CREATE_GAME_FAIL: 'CREATE_GAME/FAIL',
  GET_GAME: 'GET_GAME',
  GET_GAME_SUCCESS: 'GET_GAME/SUCCESS',
  CREATE_PLAYER: 'CREATE_PLAYER',
  CREATE_PLAYER_SUCCESS: 'CREATE_PLAYER/SUCCES',
  CREATE_PLAYER_FAIL: 'CREATE_PLAYER/FAIL',
  GET_PLAYERS: 'GET_PLAYERS',
  GET_PLAYERS_SUCCESS: 'GET_PLAYERS/SUCCESS',
  GET_PLAYERS_FAIL: 'GET_PLAYERS/FAIL',
  CLEAR_BOARD: 'CLEAR_BOARD',
  GET_GAME_ISSUES: 'GET_GAME_ISSUES',
  GET_GAME_ISSUES_SUCCESS: 'GET_GAME_ISSUES/SUCCESS',
  GET_GAME_ISSUES_FAIL: 'GET_GAME_ISSUES/FAIL',
  CREATE_GAME_ISSUES: 'CREATE_GAME_ISSUES',
  CREATE_GAME_ISSUES_SUCCESS: 'CREATE_GAME_ISSUES/SUCCESS',
  CREATE_GAME_ISSUES_FAIL: 'CREATE_GAME_ISSUES/FAIL',
  GET_GAME_ISSUES_VOTES: 'GET_GAME_ISSUES_VOTES',
}

const INITIAL_STATE = {
  game_id: null,
  data: null,
  already_seated: false,
  players: [],
  issues_votes: [],
  issues: [],
  loading: false,
  error: false,
}

export default function createGame(state = INITIAL_STATE, action) {
  const {
    CREATE_GAME,
    GET_GAME,
    GET_GAME_SUCCESS,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAIL,
    CREATE_PLAYER,
    CREATE_PLAYER_SUCCESS,
    CREATE_PLAYER_FAIL,
    GET_PLAYERS,
    GET_PLAYERS_SUCCESS,
    GET_PLAYERS_FAIL,
    GET_GAME_ISSUES,
    GET_GAME_ISSUES_SUCCESS,
    GET_GAME_ISSUES_FAIL,
    CREATE_GAME_ISSUES,
    CREATE_GAME_ISSUES_SUCCESS,
    CREATE_GAME_ISSUES_FAIL,
    GET_GAME_ISSUES_VOTES,
    CLEAR_BOARD,
  } = Types
  switch (action.type) {

    case CLEAR_BOARD:
      return {
        game_id: null,
        data: null,
        already_seated: false,
        players: [],
        issues: [],
        issues_votes: [],
        loading: false,
        error: false,
      }
    case GET_GAME:
      return {...state, game_id: action.payload, loading: true}
    case GET_GAME_SUCCESS:
      return {
        ...state,
        data: action.payload,
        game_id: action.payload.id,
        loading: false,
        error: false,
      }
    case CREATE_GAME_SUCCESS:
      return {
        INITIAL_STATE
      }
    case CREATE_GAME_FAIL:
      return {...state, loading: false, error: true}
    case CREATE_GAME:
      return {...state, loading: true}
    case CREATE_PLAYER:
      return {...state, loading: true, error: false}
    case CREATE_PLAYER_SUCCESS:
      return {...state, loading: false, error: false, already_seated: action.payload}
    case CREATE_PLAYER_FAIL:
      return {...state, loading: false, error: true}
    case GET_PLAYERS:
      return {...state, loading: true, error: false}
    case GET_PLAYERS_SUCCESS:
      return {...state, players: action.payload, loading: false, error: false}
    case GET_PLAYERS_FAIL:
      return {...state, issues: action.payload, loading: false, error: true}
    case GET_GAME_ISSUES:
      return {...state, loading: true, error: false}
    case GET_GAME_ISSUES_SUCCESS:
      return {...state, issues: action.payload, loading: false, error: false}

    case GET_GAME_ISSUES_FAIL:
      return {...state, loading: false, error: true}
    case CREATE_GAME_ISSUES:
      return {...state, loading: true, error: false}
    case CREATE_GAME_ISSUES_SUCCESS:
      return {...state, loading: false, error: false}
    case CREATE_GAME_ISSUES_FAIL:
      return {...state, loading: false, error: true}
    case GET_GAME_ISSUES_VOTES:
      return {...state, issues_votes: action.payload, loading: false, error: false}
    default:
      return state
  }
}

export const Creators = {
  createGame: (payload) => ({
    type: Types.CREATE_GAME,
    payload,
  }),

  createGameSuccess: (payload) => ({
    type: Types.CREATE_GAME_SUCCESS,
    payload,
  }),
  createGameFail: () => ({
    type: Types.CREATE_GAME_FAIL,
  }),
  getGame: (payload) => ({
    type: Types.GET_GAME,
    payload,
  }),
  getGameSuccess: (payload) => ({
    type: Types.GET_GAME_SUCCESS,
    payload,
  }),
  createPlayer: (payload) => ({
    type: Types.CREATE_PLAYER,
    payload,
  }),
  createPlayerSuccess: (payload) => ({
    type: Types.CREATE_PLAYER_SUCCESS,
    payload,
  }),
  createPlayerFail: () => ({
    type: Types.CREATE_PLAYER_FAIL,
  }),
  getPlayers: (payload) => ({
    type: Types.GET_PLAYERS,
    payload,
  }),
  getPlayersSuccess: (payload) => ({
    type: Types.GET_PLAYERS_SUCCESS,
    payload,
  }),
  getPlayersFail: () => ({
    type: Types.GET_PLAYERS_FAIL,
  }),
  createIssues: (payload) => ({
    type: Types.CREATE_GAME_ISSUES,
    payload,
  }),
  createIssuesSuccess: (payload) => ({
    type: Types.CREATE_GAME_ISSUES_SUCCESS,
    payload,
  }),
  createIssuesFaiFail: () => ({
    type: Types.CREATE_GAME_ISSUES_FAIL,
  }),
  getGameIssuesSuccess: (payload) => ({
    type: Types.GET_GAME_ISSUES_SUCCESS,
    payload,
  }),
  getGameIssuesVotes: (payload) => ({
    type: Types.GET_GAME_ISSUES_VOTES,
    payload,
  }),
  clearBoard: () => ({
    type: Types.CLEAR_BOARD,
  }),
}
