export const Types = {
  CREATE_AUTH_ANON: "CREATE_AUTH_ANON",
  CREATE_AUTH_ANON_SUCCESS: "CREATE_AUTH_ANON/SUCCESS",
  CREATE_AUTH_ANON_SUCCESS_FAIL: "CREATE_AUTH_ANON/FAIL"
};

const INITIAL_STATE = {
  authenticated: false,
  loading: false,
  error: false
};

export default function Auth(state = INITIAL_STATE, action) {
  const { CREATE_AUTH_ANON, CREATE_AUTH_ANON_SUCCESS, CREATE_AUTH_ANON_SUCCESS_FAIL } = Types;
  switch (action.type) {
    case CREATE_AUTH_ANON:
      return { ...state, loading: true };
    case CREATE_AUTH_ANON_SUCCESS:
      return {
        ...state,
        authenticated: action.payload,
        loading: false
      };
    case CREATE_AUTH_ANON_SUCCESS_FAIL:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export const Creators = {
  createAuthAnon: payload => ({
    type: Types.CREATE_AUTH_ANON,
    payload
  }),

  createAuthAnonSuccess: payload => ({
    type: Types.CREATE_AUTH_ANON_SUCCESS,
    payload
  }),
  createAuthAnonFail: () => ({
    type: Types.CREATE_AUTH_ANON_SUCCESS_FAIL
  })
};
