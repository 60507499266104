/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Form} from 'react-bootstrap'

type Props = {
  className: string
}

const TablesWidget13: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Voting history</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>There are 2 voting history</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}

          <Form.Select aria-label='Default select example' className='mt-1 w-250px'>
            <option value='1' selected>
              All meetings
            </option>
          </Form.Select>
          {/* begin::Menu 2 */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 mt-5'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-100px'>Issue name</th>
                <th className='min-w-140px'>Result</th>
                <th className='min-w-120px'>Agreement</th>
                <th className='min-w-120px'>Duration</th>
                <th className='min-w-120px'>Date</th>
                <th className='min-w-120px'>Voted/total</th>
                <th className='min-w-100px text-end'>Player results</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td className='text-dark fw-bold text-hover-primary fs-6'>Issue 1</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>13</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>Agreed</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>01:44</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>22 Jun, 10:23</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>4/5</td>
                <td className='text-dark fw-bold text-hover-primary fs-6 text-end'>Bryan(13)</td>
              </tr>
              <tr>
                <td className='text-dark fw-bold text-hover-primary fs-6'>Issue 2</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>13</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>Disagreed</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>00:11</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>23 Feb, 10:23</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>2/2</td>
                <td className='text-dark fw-bold text-hover-primary fs-6 text-end'>Bryan(13)</td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget13}
