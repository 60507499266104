/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {Languages} from './Languages'
import {KTIcon} from '../../../helpers'

import {updatePlayer} from '../../../../app/services/games'
import {Creators as gameActions} from '../../../../app/store/ducks/game'

import {useSelector, useDispatch} from 'react-redux'

const HeaderUserMenu: FC = () => {
  const auth = useSelector((state: any) => state.auth)
  const game = useSelector((state: any) => state.game)
  const dispatch = useDispatch()
  const intl = useIntl()

  //add to specific context
  function displayName() {
    const authDisplayName = auth?.authenticated?.displayName 
    const alreadySeatDisplayName = game?.already_seated?.name;

    if(auth.displayName) {
      return authDisplayName
    } else if(alreadySeatDisplayName) {
      return alreadySeatDisplayName
    } else {
      return "Join"
    }
  }

  useEffect(()=> {

  }, [game])


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {game?.already_seated && window.location.pathname.split('/')[1] === 'game' && (
        <>
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
                <KTIcon iconName='user' className='ms-1 fs-1 text-primary me-0' />
              </div>

              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center fs-5'>
                  {displayName()}
                  {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
                </div>
                {/* <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              anilton.veigaa@gmail.com
            </a> */}
              </div>
            </div>
          </div>
          <div className='separator my-2'></div>
        </>
      )}
      {/* 
      <div className='separator my-2'></div>
 */}
      {/* <div className='btn btn-light mx-3'>Start free trial</div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/game'} className='menu-link px-5'>
          <i className='fa fa-sign-in mr-2'></i>
          Login
        </Link>
        <Link to={'/game'} className='menu-link px-5'>
          <i className='fa fa-user mr-2'></i>
          Sign up
        </Link>
      </div> */}
      {game?.already_seated && window.location.pathname.split('/')[1] === 'game' && (
        <OverlayTrigger
          placement='left'
          delay={{show: 250, hide: 400}}
          overlay={(props) => (
            <Tooltip id='button-tooltip' {...props}>
              {intl.formatMessage({id: 'VIEWER.BUTTON.HELP'})}
            </Tooltip>
          )}
        >
          <div className='menu-item px-5'>
            <label className='toggle-menu px-5 form-check form-switch form-check-custom form-check-solid space-between'>
              <span>{intl.formatMessage({id: 'USER.VIEWER'})}</span>
              <input
                className='form-check-input w-30px h-20px'
                type='checkbox'
                value={game?.already_seated?.observer}
                defaultChecked={game?.already_seated?.observer}
                name='spectator'
                onChange={(e) => {
                  const {observer, ...restData} = game?.already_seated
                  const payload = {
                    ...restData,
                    player_id: game?.already_seated?.player_id,
                    observer: !game?.already_seated?.observer,
                  }
                  updatePlayer(payload, "observer")
                  dispatch(gameActions.createPlayerSuccess(payload))
                }}
              />
            </label>
          </div>
        </OverlayTrigger>
      )}

      {/* <div className='menu-item px-5'>
        <label className='toggle-menu px-5 form-check form-switch form-check-custom form-check-solid space-between'>
          <span>Fun features</span>
          <input
            className='form-check-input w-30px h-20px'
            type='checkbox'
            value='1'
            defaultChecked={true}
            name='funfeatures'
          />
        </label>
      </div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/game'} className='menu-link px-5'>
          Games 
          <span className="menu-arrow"></span>
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a href='https://planningpokerweb.com/contact' target='_blank' className='menu-link px-5'>
          {intl.formatMessage({id: 'AUTH.GENERAL.CONTACT'})}
        </a>
      </div>

      <Languages />

      {/* <div className='menu-item px-5'>
        <Link to={'/game'} className='menu-link px-5'>
          Legal notice
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/game'} className='menu-link px-5'>
          FAQs
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div> */}
    </div>
  )
}

export {HeaderUserMenu}
