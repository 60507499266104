import axios from 'axios';

export const baseURL = 'https://api.clickup.com/api/';

const API_VERSION = 'v2';



const api = axios.create({
    baseURL: `${baseURL}/${API_VERSION}`,
});


export const getTeams = async (personalToken) => {
  const response = await api.get(`${baseURL}/team`, {headers: {
            Authorization: personalToken

  }})
  console.log("clickup teams response", response)
}

// import fetch from 'node-fetch';

// async function run() {
//   const resp = await fetch(
//     `https://api.clickup.com/api/v2/team`,
//     {
//       method: 'GET',
//       headers: {
//         Authorization: 'YOUR_API_KEY_HERE'
//       }
//     }
//   );

//   const data = await resp.text();
//   console.log(data);
// }

// run();
