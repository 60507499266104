/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Dropdown3} from '../Dropdown3'

type Props = {
  className: string
}

const ListsWidget3: React.FC<Props> = ({className}) => {
  return (
    <div className={`card custom-card ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Issue</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown3 />
        </div>
      </div>
      <div className='card-body pt-2'>
        <div className='d-flex align-items-center'>
          <span className='bullet bullet-vertical h-40px bg-success'></span>
          <div className='flex-grow-1 mx-5'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Found Issue
            </a>
            <span className='text-muted fw-semibold d-block'>23131Issue</span>
          </div>
          <div className='btn btn-info mx-5 '>30</div>
          <span className='btn btn-success badge badge-light-success fs-8 fw-bold '>
            Vote this issue
          </span>
        </div>
      </div>
    </div>
  )
}

export {ListsWidget3}
