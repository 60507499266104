import React, {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../_metronic/helpers'
import {IssueList} from './lists/IssueList'
import {EditIssueModal} from './modals/EditIssueModal'
import {Dropdown} from 'react-bootstrap'
import {useSelector, useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../_metronic/helpers'

import {MenuComponent} from '../../_metronic/assets/ts/components/MenuComponent'

import {read, utils, writeFile} from 'xlsx'

import {ReactSortable} from 'react-sortablejs'

import {IssueModal} from '../components/modals/IssueModal'
import {updateGame, updateIssue, deleteIssue} from '../services/games'

import {util} from 'prettier'

import {getQuote} from '../services/quotes'
import {useIntl} from 'react-intl'

type Props = {
  show: boolean
  handleClose: () => void
}

const IssueBar: React.FC<Props> = ({show, handleClose}) => {
  const auth = useSelector((state: any) => state.auth)
  const game = useSelector((state: any) => state.game)

  const intl = useIntl()

  //   const close = 'bg-body drawer drawer-end'
  //   const show = 'bg-body drawer drawer-end drawer-on'
  useEffect(() => {
    if (show) {
      document.getElementById('kt-wrapper')?.classList.add('pull-left-wrapper')
      document.getElementById('issuebar')?.classList.add('drawer-on')
    } else {
      document.getElementById('kt-wrapper')?.classList.remove('pull-left-wrapper')
      document.getElementById('issuebar')?.classList.remove('drawer-on')
    }
  }, [show])

  const [showIssueModal, setShowIssueModal] = useState(false)

  const handleModalClose = () => setShowIssueModal(false)

  const isOwner = game?.data?.user_id === auth?.authenticated?.uid

  const gameIssues = game?.issues

  const [issues, setIssues] = useState(game?.issues || [])
  const [singleIssue, setSingleIssue] = useState(null)

  const [editIssueshow, setEditIssueshow] = useState(false)
  // const handleEditIssueClose = () => setEditIssueshow(false)
  // const handleEditIssueShow = () => setEditIssueshow(true)

  function handleEditClick(issue: any) {
    setSingleIssue(issue)
    setEditIssueshow(true)
  }

  function handleEditIssueClose() {
    setEditIssueshow(false)
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 300)
  }, [game?.issues])

  function exportToCsv() {
    const date = new Date()
    const fileName = `${game?.data?.name.split(' ').join('-')}-${date.toLocaleString()}.csv`
    const headings = [['Title', 'Description', 'Type', 'Url', 'Average']]
    const wb = utils.book_new()
    const ws = utils.json_to_sheet([])
    const fileConfig = {origin: 'A2', skipHeader: true}

    const issuesToExport = gameIssues.map(({title, description, type, uri, average}: any) => {
      return {
        title: title,
        description: description,
        type: type,
        uri: uri ? uri : '',
        average: average,
      }
    })

    utils.sheet_add_aoa(ws, headings)
    utils.sheet_add_json(ws, issuesToExport, fileConfig)
    utils.book_append_sheet(wb, ws, 'Report')
    writeFile(wb, fileName)
  }

  const handleUpdateListIndex = (newOrder: any) => {
    const oldArray = gameIssues
    const newArray = newOrder

    if (JSON.stringify(oldArray) !== JSON.stringify(newArray)) {
      newArray.map(async (_issue: any, _index: any) => {
        if (_issue.index !== _index) {
          await updateIssue({issue_id: _issue.issue_id, index: _index}, {type: 'reorder'})
        }
      })
    }
  }

  // const onSaveNewIssue = () => {
  //   console.log(newIssueRef.current?.value)
  //   const newIssue = {
  //     name: 'PP-' + (issues.length + 1),
  //     desc: String(newIssueRef.current?.value),
  //     point: '-',
  //   }
  //   setIssues([])
  //   setNewstate(false)
  // }

  // useEffect(()=> {

  // }, [game])

  function renderTotalPoints() {
    const totalSum = game?.issues?.reduce(
      (acumulador: any, issue: any) => acumulador + parseFloat(issue.average),
      0
    )

    if (isNaN(totalSum)) {
      return null
    } else {
      return (
        <div className='d-flex justify-content-end me-15'>
          <span className='fs-7 fw-bold text-gray-600'>
            Total points so far: <span className='fs-7 fw-bold text-gray-800'>{totalSum}</span>
          </span>
        </div>
      )
    }
  }

  return (
    <>
      <div
        id='issuebar'
        // id='kt_drawer_chat'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-close='#kt_drawer_chat_close2'
      >
        <IssueModal
          action='create'
          issue={singleIssue}
          show={showIssueModal}
          handleClose={handleModalClose}
        />

        <div className='w-100' id='kt_drawer_chat_messenger'>
          <div
            className='card-header justify-content-between border-bottom d-flex ps-10 py-6 pe-3 w-100'
            id='kt_drawer_chat_messenger_header'
          >
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <div className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                  {intl.formatMessage({id: 'GAMES.TASKS'})}
                </div>
                {gameIssues?.length > 0 && (
                  <div className='mb-0 lh-1'>
                    <span className='fs-7 fw-bold text-gray-400'>
                      {
                        gameIssues?.filter((issue: any) => {
                          return issue.average > 0
                        })?.length
                      }{' '}
                      {intl
                        .formatMessage({
                          id:
                            gameIssues?.filter((issue: any) => {
                              return issue.average > 0
                            })?.length > 1
                              ? 'GAMES.TASKS'
                              : 'GAMES.TASK',
                        })
                        .toLowerCase()}{' '}
                      {intl.formatMessage({id: 'TASKS.VOTED_OF'})} {gameIssues?.length}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='card-toolbar d-flex'>
              {isOwner && (
                <div className='me-2'>
                  <Dropdown>
                    <Dropdown.Toggle id='dropdown-basic' className='btn btn-sm btn-secondary'>
                      <i className='fa fa-download' /> {intl.formatMessage({id: 'TASKS.EXPORT'})}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => exportToCsv()}>to CSV</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              <div className='btn btn-sm btn-icon btn-active-light-primary' onClick={handleClose}>
                <KTIcon iconName='cross' className='fs-2' />
              </div>
            </div>
          </div>

          <IssueModal
            action='update'
            issue={singleIssue}
            show={editIssueshow}
            handleClose={handleEditIssueClose}
          />
          <ReactSortable
            disabled={!isOwner}
            animation={200}
            delay={2}
            list={game?.issues || []}
            setList={(e) => handleUpdateListIndex(e)}
            dragoverBubble={true}
            direction='vertical'
            handle='.drag-item'
          >
            {game?.issues?.map((issue: any) => {
              return (
                <IssueList
                  key={issue.issue_id}
                  className='card-xxl-stretch mb-xl-3'
                  name={issue.title}
                  category={issue.type}
                  index={issue.index}
                  voting_now={issue.voting_now || false}
                  description={issue.description}
                  points={issue.average}
                  handleClick={() => handleEditClick(issue.issue_id)}
                  isOwner={isOwner}
                  issue_id={issue.issue_id}
                  url={issue.uri}
                />
              )
            })}
          </ReactSortable>

          {game?.issues?.length === 0 && (
            <div className='d-flex row justify-content-center align-items-center mt-10'>
              <img
                src={toAbsoluteUrl('/media/empty-state.svg')}
                alt='Planning Poker Web'
                className='h-95px'
              />
              <p className='form-label text-center'>No task added</p>
            </div>
          )}

          {/* {renderTotalPoints()} */}

          {isOwner && (
            <div className='d-flex justify-content-center mt-5'>
              <div className='btn btn-light mb-10' onClick={() => setShowIssueModal(true)}>
                <i className='fa fa-plus text-primary'></i>{' '}
                {intl.formatMessage({id: 'GAMES.ADD_TASK'})}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {IssueBar}
