/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Dropdown3} from '../Dropdown3'

import {updateGame, updateIssue, deleteIssue} from '../../services/games'
import {useSelector, useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  issue_id: string
  name: string
  description: string
  url: string
  points: string
  voting_now: boolean
  isOwner: boolean
  category: string
  handleClick: () => void
  index: string
}

const IssueList: React.FC<Props> = ({
  issue_id,
  isOwner,
  index,
  className,
  category,
  name,
  voting_now,
  url,
  description,
  points,
  handleClick,
}) => {
  const [seeMore, setSeemMore] = useState(false)
  const intl = useIntl()

  const game = useSelector((state: any) => state.game)

  function returnStyle() {
    let type = null
    switch (category) {
      case 'feature':
        type = 'light-primary'
        break
      case 'bug':
        type = 'light-danger'
        break
      case 'datafix':
        type = 'light-warning'
        break
      case 'chore':
        type = 'light-dark'
        break
      default:
        type = 'light-info'
    }
    return `badge badge-${type ? type : 'light'}`
  }

  async function startVote() {
    const payload = {
      game_id: game?.data?.game_id,
      issue_id: issue_id,
    }

    return await updateGame(payload)
  }

  async function voteAgain() {
    const payload = {
      game_id: game?.data?.game_id,
      issue_id: issue_id,
    }

    //updateGame(payload)

    updateIssue({issue_id: issue_id, reveal: false}, {type: 'vote-again'})

    return await updateGame(payload)
  }

  async function handleDeleteIssue() {
    if (voting_now) {
      updateGame({
        game_id: game?.data?.game_id,
        issue_id: null,
      })
    }
    const payload = {
      game_id: game?.data?.game_id,
      issue_id: issue_id,
    }

    deleteIssue({issue_id: issue_id})

    //return await updateGame(payload)
  }

  const badgeStyle = returnStyle()

  return (
    <>
      <div
        id={issue_id === game?.data?.issue_votting_now ? 'active' : 'not-active'}
        key={issue_id}
        className={`bg-body ${
          issue_id === game?.data?.issue_votting_now ? 'border-start border-primary border-3' : ''
        }`}
      >
        {isOwner && (
          <i className='fas drag-item fa-grip-lines fs-4 ms-2 position-absolute btn-light'></i>
        )}
        <div className={` d-flex flex-column ps-10 pe-10 py-5 my-1 border-bottom border-1`}>
          {/* {issue_id === game?.data?.issue_votting_now && (
          <div className='test-2 text-custom-white'>
          </div>
        )} */}
          <div className='mb-1 d-flex justify-content-between'>
            {/* <span className='text-gray-400 fs-7 me-2'>#2123</span> */}
            <span className={`${badgeStyle}`}>{category}</span>
            {isOwner && (
              <div className='d-flex'>
                <div
                  className='cursor'
                  onClick={() => {
                    handleDeleteIssue()
                  }}
                >
                  <KTIcon iconName='trash' className='fs-3 text-danger me-4' />
                </div>
                <div
                  className='cursor'
                  onClick={() => {
                    handleClick()
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </div>
              </div>
            )}
          </div>
          <div className='fs-6 text-gray-800 fw-bold'>{name}</div>
          {seeMore && (
            <>
              <span className='text-gray-400 fs-7 me-2 mt-4'>
                {intl.formatMessage({id: 'TASKS.DESCRIPTION'})}
              </span>
              <div className='fs-6 text-gray-700  custom-pre-line'>{description}</div>
              {url !== '' && (
                <div className='mt-2'>
                  <span className='text-gray-400 fs-7 me-2 mt-4'>URL</span>
                  <a
                    href={url}
                    target='_blank'
                    className='fs-6 text-dark custom-pre-line'
                    rel='noreferrer'
                  >
                    <i className='fas fa-link fs-7 me-2'></i> {url}
                  </a>
                </div>
              )}
            </>
          )}
          {description.length > 0 && description !== 'false' && (
            <div className='d-flex align-items-center'>
              <div
                className='btn btn-link fs-8 btn-color-gray-500 btn-active-color-primary me-5'
                onClick={() => setSeemMore(!seeMore)}
              >
                <i className={`fas fa-square-caret-${seeMore ? 'up' : 'down'} fs-6`}></i>
                {seeMore
                  ? intl.formatMessage({id: 'TASKS.SHOW_LESS'})
                  : intl.formatMessage({id: 'TASKS.SHOW_MORE'})}
              </div>
            </div>
          )}
          <div className='d-flex flex-direction column justify-content-end mt-5'>
            {issue_id === game?.data?.issue_votting_now && (
              <div className='btn btn-white text-primary'>
                <i className='fas fa-fire fs-4 me-2 text-primary'></i>{' '}
                {intl.formatMessage({id: 'TASK.VOTING_NOW'})}
              </div>
            )}
            {/* <p>Issue Voting Now: {JSON.stringify(game?.data)} //</p>
          <p>Issue Id: {JSON.stringify(issue_id)} //</p> */}

            {isOwner && game?.data?.issue_votting_now !== issue_id && parseInt(points) !== 0 && (
              <div className='btn btn-light' onClick={() => voteAgain()}>
                <i className='fas fa-clock-rotate-left fs-4 me-2'></i>{' '}
                {intl.formatMessage({id: 'TASK.VOTE_AGAIN'})}
              </div>
            )}

            {parseInt(points) === 0 && issue_id !== game?.data?.issue_votting_now && isOwner && (
              <div className='btn btn-light' onClick={() => startVote()}>
                <i className='fas fa-play fs-4 me-2'></i> {intl.formatMessage({id: 'TASK.VOTE'})}
              </div>
            )}
            <div className='d-flex'>
              {isOwner ? (
                <>
                  <div
                    className='btn btn-white'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-kt-menu-target={`${issue_id}-sub`}
                    id={`${issue_id}-sub`}
                  >
                    <i className='fas fa-medal fs-4 me-2'></i> {parseInt(points) === 0 ? 0 : points}
                  </div>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
                    data-kt-menu='true'
                    id={`${issue_id}-sub`}
                  >
                    <div>
                      <div className='game-cards'>
                        {game?.data?.voting_system?.split(',').map((card: any, i: any) => {
                          return (
                            <button
                              key={`${card}-${i}`}
                              className={`game-card1`}
                              id={'game-card-' + i}
                              onClick={() => {
                                updateIssue(
                                  {
                                    issue_id: issue_id,
                                    average: card,
                                  },
                                  {type: 'update'}
                                )
                              }}
                            >
                              {card}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='btn btn-white'>
                  <i className='fas fa-medal fs-4 me-2'></i> {parseInt(points) === 0 ? 0 : points}
                </div>
              )}
            </div>
          </div>
          {/*<div className='card-header border-0'>
         <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown3 />
        </div> 
      </div>*/}
          {/* <div className='card-header border-0'>
        <h4 className='card-title fs-15 fw-bold text-dark'>
          Adicionar botão de editar na tela
          <div onClick={handleClick}>
            <KTIcon iconName='pencil' className='fs-2 btn' />
          </div>
        </h4>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown3 />
        </div>
      </div>
      <div className='card-body pt-2'>
        <div className='d-flex align-items-center'>
          <span className='bullet bullet-vertical h-40px bg-success'></span>
          <div className='flex-grow-1 mx-3'>
            <div className='text-gray-800 text-hover-primary fw-bold fs-6'>{name}</div>
            <span className='text-muted fw-semibold d-block'>{desc}</span>
          </div>
          <div className='btn btn-info mx-3 '>{points}</div>
          <span className='btn btn-success badge badge-light-success fs-8 fw-bold '>
            Vote this issue
          </span>
        </div>
      </div> */}
        </div>
      </div>
    </>
  )
}

export {IssueList}
