import {Account, AppwriteException, Databases, ID, Query} from 'appwrite'
import {
  collection,
  addDoc,
  Timestamp,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  query,
  where,
} from 'firebase/firestore'
import {getAuth, signInAnonymously, updateProfile, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import {ref, set } from "firebase/database";


import client from './api'

import {db, app, dbrealtime} from './firebase'

const account = new Account(client)
const databases = new Databases(client)

/** Auth */

// export const checkSession = async () => {
//   try {
//     const session = await account.get()
//     return session
//   } catch (error) {
//     const appwriteError = error as AppwriteException
//     throw new Error(appwriteError.message)
//   }
// }

// export const createAnonymousSession = async () => {
//   try {
//     const anonymousSession = await account.createAnonymousSession()
//     console.log(anonymousSession)

//     return anonymousSession
//   } catch (error) {
//     const appwriteError = error as AppwriteException
//     throw new Error(appwriteError.message)
//   }
// }

/** Auth Anonimously */
export const createAccountAnon = async () => {
  const auth = getAuth(app)
  const user = auth.currentUser
  
  const authenticated = await signInAnonymously(auth)
    .then((e) => {
      const {user} = e
      const {accessToken, auth, metadata, proactiveRefresh, providerId, stsTokenManager, ...restdata} = user;
      return restdata
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      // ...
    })


  return authenticated
}

export const googleSignUp = async () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  const auth = getAuth(app)
  auth.languageCode = auth.useDeviceLanguage()

  signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    console.log("token: ->", user)

    // The signed-in user info.
    const user = result.user;
    console.log("user: ->", user)
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });

}

export const updateUserInfo = async (playerName) => {

  const auth = getAuth(app);
  updateProfile(auth.currentUser, {
    displayName: playerName,
  }).then(() => {
    // return console.log("perfil atualizado")
  }).catch((error) => {
    // return console.log("perfil nao atualizado")

  });
}

export const poolingPlayerConecctivity = async (uid, game_id) => {
  try {
    const playerRef = await getDoc(doc(db, 'players', where("user_id", "==", uid), where("game_id", "==", game_id)))
    await setDoc(playerRef, {
      timestamp: serverTimestamp()

  });

  } catch (err) {
    console.log(err)
  }
}

// export const updateUserInfo = async ({payload}) => {
//   console.log("action,name", payload.name)
//   const auth = getAuth();
//   const data = await updateProfile(auth.currentUser, {
//     displayName: payload.name, 
//   }).then((e) => {
//     return e
//   }).catch((error) => {
//     // An error occurred
//     // ...
//   });

//   console.log("DEu tudo certo mesmo",data)

//   // try {
//   //   const game = await databases.createDocument('planningpokerweb', 'games', ID.unique(), payload)
//   //   return game
//   // } catch (error) {
//   //   const appwriteError = error as AppwriteException
//   //   throw new Error(appwriteError.message)
//   // }
// }

export const createPlayer = async ({payload}) => {
  try {
    const player = await addDoc(collection(db, 'players'), {
      ...payload,
      connected: true,
      created: Timestamp.now(),
    })

    const playerData = await getDoc(doc(db, 'players', player.id))

    return playerData.data()
  } catch (err) {
    alert(err)
  }

  // try {
  //   const player = await databases.createDocument('planningpokerweb', 'players', ID.unique(), payload)

  //   return player
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const getGame = async ({payload}) => {
  const gameRef = doc(db, 'games', payload)
  const getGame = await getDoc(gameRef)
  const gameData = getGame.data()
  return {...gameData, game_id: gameRef.id}

  // try {
  //   const game = await databases.getDocument('planningpokerweb', 'games', payload)
  //   return game
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}

export const getPlayers = async ({payload}) => {
  const players = query(collection(db, 'players'), where('game_id', '==', payload))
  // players.data()
  // try {
  //   const players = await databases.listDocuments('planningpokerweb', 'players', [
  //     Query.equal('game_id', payload),
  //   ])
  //   return players
  // } catch (error) {
  //   const appwriteError = error as AppwriteException
  //   throw new Error(appwriteError.message)
  // }
}
