import React, {useState} from 'react'
import {KTIcon} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'



type Props = {
  show: boolean
  handleClose: () => void
}

const InviteModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()

  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text:any) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, window.location.href);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(window.location.href)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }



  return (
    <Modal show={show} onHide={handleClose} keyboard={false} className='inviteModal' centered>
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
        <div className='mb-13'>
          <h1 className='mb-3'> {intl.formatMessage({id: 'INVITEYOURTEAM'})}</h1>
        </div>

        <input
          className='form-control form-control-solid mb-4'
          placeholder="Game's url"
          defaultValue={window.location.href}
          readOnly
        />

          <div className='btn btn-primary w-100 mb-8 mt-2 btn' onClick={() => handleCopyClick()}>
          {isCopied ? intl.formatMessage({id: 'COPIED'}) : intl.formatMessage({id: 'COPY'})}
        </div>
      </div>
    </Modal>
  )
}

export {InviteModal}
