import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Confetti from 'react-confetti'

import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
} from 'react-share'

import {KTSVG} from '../../../_metronic/helpers'

import {collection, doc, onSnapshot, query, where} from 'firebase/firestore'

import {db} from '../../services/firebase'

import {createVote, updateVote, deleteVote, updateIssue, updateGame} from '../../services/games'

/** Games */
import {Creators as gameActions} from '../../store/ducks/game'

import {MixedWidget7} from '../../../_metronic/partials/widgets/mixed/MixedWidget7'
import {UserActionsModal} from '../../../_metronic/layout/components/header/UserActionsModal'

const GameBoard = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const game = useSelector((state: any) => state.game || {})
  const auth = useSelector((state: any) => state.auth)

  const [players, setPlayers] = useState(game?.players || [])
  const [issuesVotes, setIssuesVotes] = useState(game?.data?.issue_votes || [])
  const [issueVoting, setIssueVoting] = useState(game?.data?.issue_votting_now || null)
  const [currentVote, setCurrentVote] = useState<any>(null)
  const [playersToCalculate, setPlayersToCalculate] = useState<any>(null)
  const [reveal, setReveal] = useState<any>(
    game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal
  )
  const [result, setResult] = useState<any>(null)

  const [showUserActionsModal, setShowUserActionsModal] = useState<any>(false)
  const [actionsModalTo, setActionsModalTo] = useState<any>(null)

  const isOwner = game?.data?.user_id === auth?.authenticated?.uid

  function handleShowActionsModal(player: any) {
    if (isOwner === true) {
      setShowUserActionsModal(true)
      setActionsModalTo(player)
    }
  }

  function handleCloseActionsModal() {
    setShowUserActionsModal(false)
    setActionsModalTo(null)
  }

  function checkRemovedPlayers() {
    const seated = game?.already_seated?.player_id
    if (!!seated) {
      const connectedPlayer = game?.players.filter(
        (_player: any) => _player.player_id === game?.already_seated?.player_id
      )
      if (
        connectedPlayer.length === 0 &&
        players.length > 0 &&
        game?.already_seated?.game_id === window.location.pathname.split('game/')[1]
      ) {
        dispatch(gameActions.clearBoard())
        return window.location.replace('https://planningpokerweb.com/?action=removed')
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      checkRemovedPlayers()
    }, 2500)
  }, [players])

  function returnStyle(category: any) {
    let type = null
    switch (category) {
      case 'feature':
        type = 'primary'
        break
      case 'bug':
        type = 'danger'
        break
      case 'datafix':
        type = 'warning'
        break
      case 'chore':
        type = 'dark'
        break
      default:
        type = 'info'
    }
    return `badge badge-${type ? type : 'light'}`
  }

  /** INIT USEEFFECT */

  useEffect(() => {
    if (game?.data?.issue_votting_now !== issueVoting && game?.loading === false) {
      setIssueVoting(game?.data?.issue_votting_now)
      setCurrentVote(null)
      //why I add this handle vote again on this handleVoteAgainBoard()
      //handleVoteAgainBoard()
      setReveal(game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal)
    }
  }, [game])

  //count votes for everybody
  useEffect(() => {
    setTimeout(() => {
      const result = handleObject()
      setResult(result)
    }, 600)
  }, [game, issuesVotes])

  useEffect(() => {
    dispatch(gameActions.getGame(window.location.pathname.split('game/')[1]))

    onSnapshot(doc(db, 'games', window.location.pathname.split('game/')[1]), (doc) => {
      if (doc) {
        if (doc.data()?.issue_votting_now) {
          onSnapshot(
            query(
              collection(db, 'issues_votes'),
              where('game_id', '==', window.location.pathname.split('game/')[1]),
              where('issue_id', '==', doc.data()?.issue_votting_now)
            ),
            (querySnapshot) => {
              const issuesVotes: any = []
              querySnapshot.forEach((doc) => {
                issuesVotes.push({...doc.data(), issue_vote: doc.id})
              })
              dispatch(gameActions.getGameIssuesVotes(issuesVotes))
              setIssuesVotes(issuesVotes)
            }
          )
        }
        return dispatch(gameActions.getGameSuccess({...doc.data(), game_id: doc.id}))
      }
    })

    onSnapshot(
      query(
        collection(db, 'players'),
        where('game_id', '==', window.location.pathname.split('game/')[1]),
        where('connected', '==', true)
      ),
      (querySnapshot) => {
        const players: any = []
        querySnapshot.forEach((doc) => {
          if (doc) players.push({...doc.data(), player_id: doc.id})
        })
        dispatch(gameActions.getPlayersSuccess(players))
        setPlayers(players)
        setPlayersToCalculate(players.filter((player: any) => player.observer === false).length)
      }
    )

    onSnapshot(
      query(
        collection(db, 'issues'),
        where('game_id', '==', window.location.pathname.split('game/')[1])
      ),
      (querySnapshot) => {
        const issues: any = []
        querySnapshot.forEach((doc) => {
          issues.push({...doc.data(), issue_id: doc.id})
        })
        //ordernar por created_at
        const orderedDate = issues.sort((a: any, b: any) => {
          return parseInt(a.index)- parseInt(b.index)
        })
        dispatch(gameActions.getGameIssuesSuccess(orderedDate))
      }
    )
  }, [])

  useEffect(() => {
    if (issueVoting)
      onSnapshot(
        query(
          collection(db, 'issues_votes'),
          where('game_id', '==', window.location.pathname.split('game/')[1]),
          where('issue_id', '==', issueVoting)
        ),
        (querySnapshot) => {
          const issuesVotes: any = []
          querySnapshot.forEach((doc) => {
            issuesVotes.push({...doc.data(), issue_vote: doc.id})
          })
          dispatch(gameActions.getGameIssuesVotes(issuesVotes))
        }
      )
  }, [issueVoting])

  /** SNAPSHOTS */

  function renderTable(_players: any) {
    const len = _players.length

    const toparr: Array<string> = []
    const leftarr: Array<string> = []
    const bottomarr: Array<string> = []
    const rightarr: Array<string> = []

    for (let i = 0; i < len; i++) {
      if (_players[i].user_id === game?.already_seated?.user_id) bottomarr.push(_players[i])
      if (i % 4 === 0 && _players[i].user_id !== game?.already_seated?.user_id)
        toparr.push(_players[i])
      else if (i % 4 === 1 && _players[i].user_id !== game?.already_seated?.user_id)
        bottomarr.push(_players[i])
      else if (i % 4 === 2 && _players[i].user_id !== game?.already_seated?.user_id)
        leftarr.push(_players[i])
      else if (_players[i].user_id !== game?.already_seated?.user_id) rightarr.push(_players[i])
    }
    if (len === 3) {
      const temp = leftarr.pop()
      if (temp) toparr.push(temp)
    } else if (len > 3 && len < 13) {
      while (leftarr.length > 1) {
        const temp = leftarr.pop()
        if (temp) toparr.push(temp)
      }
      while (rightarr.length > 1) {
        const temp = rightarr.pop()
        if (temp) bottomarr.push(temp)
      }
    } else {
      while (leftarr.length > 2) {
        const temp = leftarr.pop()
        if (temp) toparr.push(temp)
      }
      while (rightarr.length > 2) {
        const temp = rightarr.pop()
        if (temp) bottomarr.push(temp)
      }
    }

    return (
      <div className='gamepan'>
        <div className='top-pan d-flex align-items-center justify-content-center'>
          {toparr?.map((item: any) => {
            const player_voted = issuesVotes.filter((issue: any) => issue.user_id === item.user_id)
            return (
              <div
                className='player'
                onClick={() => {
                  if (
                    player_voted.length === 0 &&
                    game?.already_seated?.user_id !== item?.user_id
                  ) {
                    handleShowActionsModal(item)
                  }
                }}
                key={item.user_id}
              >
                {game?.issues
                  ?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                  ?.average.toString() === player_voted[0]?.vote_value.trim() &&
                  game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                    ?.reveal === true && <i className='fa-solid fa-crown fs-1 text-warning'></i>}
                <div
                  className={`${
                    item.user_id === game?.already_seated?.user_id ? 'bg-primary' : ''
                  } ${
                    player_voted?.length > 0 &&
                    player_voted[0]?.user_id !== auth?.authenticated?.uid
                      ? game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                          ?.reveal === true
                        ? 'game-card bg-primary'
                        : 'game-card-table'
                      : 'game-card'
                  } `}
                >
                  {item?.observer && (
                    <i
                      className={`fas fa-eye ${
                        game?.already_seated?.observer === true &&
                        game?.already_seated?.user_id === item?.user_id
                          ? 'text-white'
                          : 'text-dark'
                      }`}
                    ></i>
                  )}
                  {player_voted.length > 0 &&
                    player_voted[0]?.user_id === auth?.authenticated?.uid && (
                      <span className='badge badge-circle badge-dark'>
                        {player_voted[0]?.vote_value}
                      </span>
                    )}
                  {player_voted.length > 0 &&
                    player_voted[0]?.user_id !== auth?.authenticated?.uid &&
                    game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                      ?.reveal === true && (
                      <span className='badge badge-circle badge-dark'>
                        {player_voted[0]?.vote_value}
                      </span>
                    )}
                </div>
                <span>{[item['name']]}</span>
              </div>
            )
          })}
        </div>
        <div className='d-flex align-items-center justify-content-center'>
          <div>
            {leftarr.map((item: any) => {
              const player_voted = issuesVotes.filter(
                (issue: any) => issue.user_id === item.user_id
              )
              return (
                <div
                  className='player'
                  onClick={() => {
                    if (
                      player_voted.length === 0 &&
                      game?.already_seated?.user_id !== item?.user_id
                    ) {
                      handleShowActionsModal(item)
                    }
                  }}
                  key={item.user_id}
                >
                  {game?.issues
                    ?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                    ?.average.toString() === player_voted[0]?.vote_value.trim() &&
                    game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                      ?.reveal === true && <i className='fa-solid fa-crown fs-1 text-warning'></i>}
                  <div
                    className={`${
                      item.user_id === game?.already_seated?.user_id ? 'bg-primary' : ''
                    } ${
                      player_voted?.length > 0 &&
                      player_voted[0]?.user_id !== auth?.authenticated?.uid
                        ? game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                            ?.reveal === true
                          ? 'game-card bg-primary'
                          : 'game-card-table'
                        : 'game-card'
                    } `}
                  >
                    {item?.observer && (
                      <i
                        className={`fas fa-eye ${
                          game?.already_seated?.observer === true &&
                          game?.already_seated?.user_id === item?.user_id
                            ? 'text-white'
                            : 'text-dark'
                        }`}
                      ></i>
                    )}
                    {player_voted.length > 0 &&
                      player_voted[0]?.user_id === auth?.authenticated?.uid && (
                        <span className='badge badge-circle badge-dark'>
                          {player_voted[0]?.vote_value}
                        </span>
                      )}
                    {player_voted.length > 0 &&
                      player_voted[0]?.user_id !== auth?.authenticated?.uid &&
                      game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                        ?.reveal === true && (
                        <span className='badge badge-circle badge-dark'>
                          {player_voted[0]?.vote_value}
                        </span>
                      )}
                  </div>
                  <span>{[item['name']]}</span>
                </div>
              )
            })}
          </div>
          <div className='gameboard'>
            <div>
              {issuesVotes.length === playersToCalculate &&
                issueVoting?.length > 3 &&
                game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal !==
                  true &&
                isOwner && (
                  <div onClick={() => handleReveal()} className='btn btn-secondary'>
                    Reveal cards
                  </div>
                )}

              {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal ===
                true &&
                isOwner && (
                  <>
                    <div onClick={() => handleVoteAgainBoard()} className='me-4 btn btn-secondary'>
                      <i className='fas fa-clock-rotate-left fs-4'></i>{' '}
                      {intl.formatMessage({id: 'TASK.VOTE_AGAIN'})}
                    </div>
                    {game?.issues?.filter((issue: any) => issue.reveal !== true)?.length > 0 && (
                      <div
                        onClick={() => {
                          startVote(
                            game?.issues?.filter((issue: any) => issue.reveal !== true)[0]?.issue_id
                          )
                        }}
                        className='btn btn-secondary'
                      >
                        {intl.formatMessage({id: 'GAMES.NEXT_TASK'})}
                      </div>
                    )}
                  </>
                )}

              {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal ===
                true &&
                !isOwner && <p className='form-label text-white'>Waiting for next task</p>}

              {issueVoting?.length > 5 && issuesVotes.length !== playersToCalculate && (
                <p className='form-label text-white'>Waiting all players vote</p>
              )}

              {!issueVoting && <p className='form-label text-white'>Waiting tasks to vote</p>}
            </div>
          </div>
          <div>
            {rightarr?.map((item: any) => {
              const player_voted = issuesVotes.filter(
                (issue: any) => issue?.user_id === item.user_id
              )
              return (
                <div
                  className='player'
                  onClick={() => {
                    if (
                      player_voted.length === 0 &&
                      game?.already_seated?.user_id !== item?.user_id
                    ) {
                      handleShowActionsModal(item)
                    }
                  }}
                  key={item.user_id}
                >
                  {game?.issues
                    ?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                    ?.average.toString() === player_voted[0]?.vote_value.trim() &&
                    game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                      ?.reveal === true && <i className='fa-solid fa-crown fs-1 text-warning'></i>}
                  <div
                    className={`${
                      item.user_id === game?.already_seated?.user_id ? 'bg-primary' : ''
                    } ${
                      player_voted?.length > 0 &&
                      player_voted[0]?.user_id !== auth?.authenticated?.uid
                        ? game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                            ?.reveal === true
                          ? 'game-card bg-primary'
                          : 'game-card-table'
                        : 'game-card'
                    } `}
                  >
                    {item?.observer && (
                      <i
                        className={`fas fa-eye ${
                          game?.already_seated?.observer === true &&
                          game?.already_seated?.user_id === item?.user_id
                            ? 'text-white'
                            : 'text-dark'
                        }`}
                      ></i>
                    )}
                    {player_voted.length > 0 &&
                      player_voted[0]?.user_id === auth?.authenticated?.uid && (
                        <span className='badge badge-circle badge-dark'>
                          {player_voted[0]?.vote_value}
                        </span>
                      )}
                    {player_voted.length > 0 &&
                      player_voted[0]?.user_id !== auth?.authenticated?.uid &&
                      game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                        ?.reveal === true && (
                        <span className='badge badge-circle badge-dark'>
                          {player_voted[0]?.vote_value}
                        </span>
                      )}
                  </div>
                  <span>{[item['name']]}</span>
                </div>
              )
            })}
          </div>
        </div>
        <p></p>

        <div className='top-pan d-flex align-items-center justify-content-center'>
          {bottomarr?.map((item: any) => {
            const player_voted = issuesVotes.filter((issue: any) => issue.user_id === item.user_id)
            return (
              <>
                <div
                  className='player'
                  key={item.user_id}
                  onClick={() => {
                    if (
                      player_voted.length === 0 &&
                      game?.already_seated?.user_id !== item?.user_id
                    ) {
                      handleShowActionsModal(item)
                    }
                  }}
                >
                  {game?.issues
                    ?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                    ?.average.toString() === player_voted[0]?.vote_value.trim() &&
                    game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                      ?.reveal === true && <i className='fa-solid fa-crown fs-1 text-warning'></i>}
                  <div
                    className={`${
                      item.user_id === game?.already_seated?.user_id ? 'bg-primary' : ''
                    } ${
                      player_voted?.length > 0 &&
                      player_voted[0]?.user_id !== auth?.authenticated?.uid
                        ? game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                            ?.reveal === true
                          ? 'game-card bg-primary'
                          : 'game-card-table'
                        : 'game-card'
                    } `}
                  >
                    {item?.observer && (
                      <i
                        className={`fas fa-eye ${
                          game?.already_seated?.observer === true &&
                          game?.already_seated?.user_id === item?.user_id
                            ? 'text-white'
                            : 'text-dark'
                        }`}
                      ></i>
                    )}
                    {player_voted.length > 0 &&
                      player_voted[0]?.user_id === auth?.authenticated?.uid && (
                        <span className='badge badge-circle badge-dark'>
                          {player_voted[0]?.vote_value}
                        </span>
                      )}
                    {player_voted.length > 0 &&
                      player_voted[0]?.user_id !== auth?.authenticated?.uid &&
                      game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                        ?.reveal === true && (
                        <span className='badge badge-circle badge-dark'>
                          {player_voted[0]?.vote_value}
                        </span>
                      )}
                  </div>
                  <span>{[item['name']]}</span>
                </div>
              </>
            )
          })}
        </div>
      </div>
    )
  }

  function handleObject() {
    const votes = issuesVotes
    const countMap: {[key: string]: number} = {}
    const numbersArray: {[key: number]: any}[] = []

    // Count occurrences of each value
    votes.forEach((item: any) => {
      let key = String(item.vote_value)
      if (key === '½') {
        key = '0.5'
      }

      const keyParsed = parseFloat(key)
      if (!isNaN(keyParsed)) {
        numbersArray.push(key)
      }
      if (countMap[key]) {
        countMap[key]++
      } else {
        countMap[key] = 1
      }
    })

    // Create an array of objects with value and count properties
    const result = Object.keys(countMap).map((key) => ({
      value: key,
      count: countMap[key],
    }))

    let average = null
    if (numbersArray.length === 0) {
      average = Math.max(...result.map((r) => r.count))
    } else {
      average =
        numbersArray.length >= 2
          ? -Math.round(
              -numbersArray.reduce(
                (accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue),
                0
              ) / numbersArray.length
            )
          : result[0].value
    }

    //calculate agreement
    const totalVotes = votes.length
    let agreedVotes = 0
    if (votes.length >= 2) {
      for (var i = 0; i < votes.length; i++) {
        for (var j = i + 1; j < votes.length; j++) {
          // Compare each vote with all other votes
          if (votes[i].vote_value === votes[j].vote_value) {
            agreedVotes++
          }
        }
      }
    }

    // Calculate the agreement percentage
    let agreementPercentage: any = (agreedVotes / ((totalVotes * (totalVotes - 1)) / 2)) * 100

    // Round the agreement percentage to two decimal places
    agreementPercentage = agreementPercentage.toFixed(2)

    // Deviation
    // Passo 1: Calcular a média dos valores
    const totalSum = numbersArray.reduce(
      (acumulador: any, vote: any) => acumulador + parseFloat(vote),
      0
    )

    const media = totalSum / votes.length

    // Passo 2: Calcular a soma dos quadrados das diferenças em relação à média
    const somaQuadrados = numbersArray.reduce((acumulador: any, item: any) => {
      const diferenca = item - media
      return acumulador + diferenca ** 2
    }, 0)

    // Passo 3: Calcular a variância
    const variancia = somaQuadrados / votes.length

    // Passo 3: Calcular o desvio padrão
    const desvioPadrao = Math.sqrt(variancia).toFixed(2)

    const cv = (parseFloat(desvioPadrao) / media) * 100

    const data = {
      votes: result,
      numbers: numbersArray,
      average: average,
      agreement: agreementPercentage.isNaN ? 0 : agreementPercentage,
      deviation: cv,
    }

    setResult(result)

    return data
  }

  function handleReveal() {
    const result = handleObject()
    updateIssue(
      {
        issue_id: issueVoting,
        average: result.average,
        reveal: !game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal,
      },
      {type: 'reveal'}
    )
  }

  async function startVote(issue_id: any) {
    const payload = {
      game_id: game?.data?.game_id,
      issue_id: issue_id,
    }

    return await updateGame(payload)
  }

  function handleVoteAgainBoard() {
    setCurrentVote(null)
    return updateIssue(
      {issue_id: game?.data?.issue_votting_now, reveal: false, issues_votes: issuesVotes},
      {type: 'vote-again'}
    )
  }

  function checkVote() {
    const already_votted = issuesVotes.filter(
      (issue_vote: any) => issue_vote.user_id === auth.authenticated.uid
    )
    return already_votted
  }

  const handleVote = async (e: number) => {
    const vote = checkVote()
    const issueVote = {
      issue_id: game?.data?.issue_votting_now,
      user_id: auth?.authenticated.uid,
      game_id: game?.data?.game_id,
      vote_value: e,
    }

    if (vote.length === 0) {
      setCurrentVote(e)
      createVote(issueVote)
    }
    if (vote.length > 0) {
      if (vote[0].vote_value === e) {
        setCurrentVote(null)
        deleteVote({vote_id: vote[0].issue_vote})
      } else {
        //atualiza o documento
        setCurrentVote(e)
        updateVote({vote_id: vote[0].issue_vote, value: e})
      }
    }
  }

  return (
    <div style={{height: '100%'}} className='d-flex flex-direction row justify-content-between'>
      <UserActionsModal
        show={showUserActionsModal}
        handleClose={handleCloseActionsModal}
        player={actionsModalTo}
      />
      <Dropdown>
        <Dropdown.Toggle variant='light' id='dropdown-basic' className='timer flex-center'>
          <svg
            width='30px'
            height='30px'
            viewBox='-2.4 -2.4 28.80 28.80'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            stroke='#ff526c'
          >
            <g id='SVGRepo_bgCarrier' stroke-width='0' />

            <g
              id='SVGRepo_tracerCarrier'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke='#CCCCCC'
              stroke-width='0.624'
            />

            <g id='SVGRepo_iconCarrier'>
              <path
                d='M15.7 4C18.87 4 21 6.98 21 9.76C21 15.39 12.16 20 12 20C11.84 20 3 15.39 3 9.76C3 6.98 5.13 4 8.3 4C10.12 4 11.31 4.91 12 5.71C12.69 4.91 13.88 4 15.7 4Z'
                stroke='#ff6666'
                stroke-width='1.752'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </g>
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className='modal-body scroll-y w-300px h-300px'>
            <div className='mb-5 mt-5 mx-5 text-gray-700'>
              <p>
                <strong>Are you enjoying?</strong>
              </p>
              <p>
              In this moment, gathered together, we're experiencing something profound that transcends mere task estimation. We're collaboratively shaping a software solution destined to touch lives.
              </p>
              <p>
              Imagine, years from now, reflecting on your career and proudly declaring, "I played a pivotal role in that achievement."
              </p>

              <p>
              Your presence here is a testament to the trust the company places in your collective expertise. <strong>Planning Poker isn't just about task estimation</strong>; it's about harnessing the collective wisdom of a team, much like a football squad where every player from the defender to the striker collaborates for that perfect goal.
              </p>
              <p>
              Planning Poker Web was conceived to amplify this synergy, ensuring that deadlines become mere checkpoints when a team is truly in sync.
              </p>
              <p>
              We invite you to champion this tool by sharing it on your social networks or with peers who might benefit. Your single click could be a game-changer for another team out there.</p>
            </div>
            <div className='mx-4 mb-5'>
              <LinkedinShareButton
                url='https://planningpokerweb.com'
                title='The Best Free Planning Poker Tool'
                className='me-1'
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <TwitterShareButton
                url='https://planningpokerweb.com'
                title='The Best Free Planning Poker Tool'
                className='me-1'
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton
                url='https://planningpokerweb.com'
                title='The Best Free Planning Poker Tool'
                className='me-1'
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <div className='d-flex align-items-center justify-content-center'>{renderTable(players)}</div>

      <div className='d-flex align-items-center justify-content-center row'>
        <div className='d-flex align-items-center justify-content-center row mt-5'>
          {issueVoting &&
            issueVoting !== false &&
            game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal ===
              true && (
              <div className='text-muted text-center fs-4'>
                <span
                  className={`${returnStyle(
                    game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.type
                  )} d-auto`}
                >
                  {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.type}
                </span>
                <p className='form-label mt-3'>
                  {intl.formatMessage({id: 'GAMES.TASK'})}:{' '}
                  {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.title}
                </p>
              </div>
            )}
        </div>
        <div className='d-flex align-items-center justify-content-center'>
          {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal ===
            true &&
            result?.votes?.length > 0 && (
              <div className='d-flex justify-content-center mt-10'>
                {result?.votes?.map((vote: any) => {
                  return (
                    <div className='reveal-left' key={`vote-${vote.value.toString()}`}>
                      <div className='long-bar'>
                        <div
                          className='long-bar-active primary'
                          style={{height: `${(vote.count / issuesVotes.length) * 100}%`}}
                        ></div>
                      </div>
                      <div className='game-card2'>{vote.value}</div>
                      <span className='text-muted font-weight-bold'>
                        {vote.count} {intl.formatMessage({id: 'GENERAL.VOTES'})}
                        {vote.count > 1 ? 's' : ''}
                      </span>
                    </div>
                  )
                })}
                {result?.deviation.toString() !== 'NaN' && (
                  <div className='reveal-right ms-10 d-flex justify-content-between align-items-center row'>
                    <div>
                      <div className='text-muted font-weight-bold text-center'>
                        {intl.formatMessage({id: 'GENERAL.AVERAGE'})}
                      </div>
                      <h5 className='text-secondary-inverse text-center'>{result?.average}</h5>
                    </div>
                    <div>
                      <div className='text-muted font-weight-bold text-center'>
                        {result?.votes?.filter((vt: any) => vt.value === '☕').length > 0
                          ? 'Coffee Time!'
                          : intl.formatMessage({id: 'GENERAL.AGREEMENT'})}
                      </div>
                      {result?.votes?.filter((vt: any) => vt.value === '☕').length > 0 ? (
                        <>
                          <p className='text-center' style={{fontSize: '50px', marginTop: 'px'}}>
                            ☕
                          </p>
                        </>
                      ) : (
                        <>
                          {result?.deviation === 0 && (
                            <Confetti
                              numberOfPieces={600}
                              recycle={false}
                              tweenDuration={1000}
                              width={window.innerWidth - 20}
                              height={window.innerHeight}
                            />
                          )}
                          <MixedWidget7
                            className='card-xl-stretch mb-xl-8'
                            chartColor='primary'
                            chartHeight='150px'
                            agreement={(100 - result?.deviation).toFixed(2)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>

        {game?.issues?.length > 0 &&
          game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.reveal !==
            true &&
          game?.already_seated?.observer === false &&
          issueVoting && (
            <div className='d-flex justify-content-center align-items-center row'>
              {issueVoting && issueVoting !== false && (
                <div className='text-muted text-center fs-4'>
                  <span
                    className={`${returnStyle(
                      game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.type
                    )} d-auto`}
                  >
                    {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.type}
                  </span>
                  <p className='form-label mt-3'>
                    {intl.formatMessage({id: 'GAMES.TASK'})}:{' '}
                    {game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]?.title}
                  </p>
                </div>
              )}

              <div className='game-cards'>
                {game?.data?.voting_system?.split(',').map((card: any, i: any) => {
                  return (
                    <button
                      key={`${card}-${i}`}
                      disabled={
                        game?.issues?.filter((issue: any) => issue.issue_id === issueVoting)[0]
                          ?.reveal
                      }
                      className={`game-card1 ${
                        card === currentVote
                          ? 'bg-primary text-light card-selected'
                          : 'text-primary'
                      }`}
                      id={'game-card-' + i}
                      onClick={() => handleVote(card)}
                    >
                      {card}
                    </button>
                  )
                })}
              </div>
            </div>
          )}

        {game?.already_seated?.observer === true && (
          <div className='d-flex justify-content-center align-items-center'>
            <p className='form-label align-center'>
              You're in a viewer mode, in order to see the cards and vote change the viewer option
              in your profile
            </p>
          </div>
        )}

        {isOwner === true && game?.issues?.length === 0 && (
          <div className='d-flex justify-content-center align-items-center'>
            <p className='form-label align-center'>
              As a moderator, to start you need to add your tasks in the menu item Tasks
            </p>
          </div>
        )}

        {isOwner === false && game?.issues?.length === 0 && (
          <div className='d-flex justify-content-center align-items-center'>
            <p className='form-label align-center'>Waiting the session moderator add tasks</p>
          </div>
        )}
      </div>
    </div>
  )
}

export {GameBoard}
