import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {Provider} from 'react-redux'

import {persistor, store} from './store'
import {PersistGate} from 'redux-persist/integration/react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react'

import './services/api'

/** POST HOG */
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

/*END POST HOG

/**Log Rocket */

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  LogRocket.init('xnvnkq/planning-poker-web')
  /** Log Rocket end */

  setupLogRocketReact(LogRocket)
}

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <I18nProvider>
          <LayoutProvider>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </PersistGate>
            </Provider>
          </LayoutProvider>
        </I18nProvider>
      </PostHogProvider>
    </Suspense>
  )
}

export {App}
