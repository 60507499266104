/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Dropdown3: FC = () => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <a href='#' className='menu-link px-3'>
          Open
        </a>
      </div>

      <div className='menu-item px-3'>
        <a href='#' className='menu-link flex-stack px-3'>
          Move to Top
        </a>
      </div>

      <div className='menu-item px-3'>
        <a href='#' className='menu-link px-3'>
          Move to Bottom
        </a>
      </div>

      <div className='menu-item px-3 my-1'>
        <a href='#' className='menu-link px-3 '>
          Delete
        </a>
      </div>
    </div>
  )
}

export {Dropdown3}
